import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

import {
  editEmployeeSelfResponse,
  finalSubmit,
  managerFinalSubmit,
  managerReview,
  organizationLeaderFinalSubmit,
  organizationLeaderReviewSave,
  peerFinalSubmit,
  peerReviewSave,
  showOrganizationLeaderAssigned360Form,
  showPeerAssigned360Form,
  showPrevious360FormsEmployee,
  viewEmployeeSelfResponse,
  viewResponse360Manager,
} from "../../../Service/Apis";
import Footer from "../../../components/Page Component/Footer";
import Navbar from "../../../components/Page Component/Navbar";

import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  ToastVariant,
  useCustomToast,
} from "../../../components/Methods/SnackBar";
import Skeletons from "../../../components/Skeleton/Skeletons";
import { postDataSuccess } from "../../../store/AxiosSlice";
import { setInPageLoading, setIsLoading } from "../../../store/LoadingSlice";
import { RootState } from "../../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  patchData,
} from "../../../store/actions";

interface ResponseType {
  response_id: string;
  form_team_id: string;
  form_id: string;
  form_name: string;
  form_description: string;
  team_id: string;
  project_name: string;
  manager: string;
  employee_id: string;
  roles: string[];
  employee_name: string;
  employee_deadline: string;
  employee_code: string;
  employee_designation: string;
  manager_deadline: string;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  response: Section[]; // Update this to use Section[] instead of [{...}]
}

interface Response1 {
  section_id: string;
  category_id: string;
  question_id: string;
  employee_response: string;
  employee_comment: string;
  employee_rating: string;
  manager_response: string;
  manager_comment: string;
  organization_leader_response: string;
  organization_leader_comment: string;
  peer_one_response: string;
  peer_one_comment: string;
  peer_two_response: string;
  peer_two_comment: string;
  peer_three_response: string;
  peer_three_comment: string;
  peer_four_response: string;
  peer_four_comment: string;
  peer_five_response: string;
  peer_five_comment: string;
}

interface Section {
  section_id: string;
  section: string;
  categories: Category[];
}

interface Category {
  category_id: string;
  category: string;
  questions: Question[];
}

interface Question {
  question_id: string;
  question_name: string;
  employee_rating: string;
  employee_response: string;
  employee_comment: string;
  manager_response: string;
  manager_comment: string;
  organization_leader_response: string;
  organization_leader_comment: string;
  peer_one_response: string;
  peer_one_comment: string;
  peer_two_response: string;
  peer_two_comment: string;
  peer_three_response: string;
  peer_three_comment: string;
  peer_four_response: string;
  peer_four_comment: string;
  peer_five_response: string;
  peer_five_comment: string;
  answer_type: string;
  answer_choices: {
    option_id: string;
    answer_value: string;
  }[];
}

interface SpecialCharErrors {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface MinCharacterError {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface SpacesErrors {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

const initialSpecialCharErrors: SpecialCharErrors = {};
const initialMinCharacterErrors: MinCharacterError = {};
const initialSpacesErrors: SpacesErrors = {};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ThreeSixtySpecificPreviousResponse = () => {
  const [viewResponse, setViewResponse] = useState<ResponseType[]>([]);
  const [roleArray, setRoleArray] = useState({
    isEmployee: false,
    isManager: false,
    isOrganizationLeader: false,
    isPeer: false,
  });
  const [responseKey, setResponseKey] = useState<keyof Question>();
  const [commentKey, setCommentKey] = useState<keyof Question>();

  const showToast = useCustomToast();

  const Navigate = useNavigate();

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  // const success = useSelector((state: any) => state.http.data);
  const peerNumber = localStorage.getItem("peerNumber");

  const formId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const formTeamId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const dispatch = useDispatch();
  dispatch(setIsLoading(false));

  const isLoading = useSelector((state: any) => state.loading.isLoading);
  const inPageLoading = useSelector(
    (state: any) => state.loading.inPageLoading
  );

  // API call to view specific response of the employee.
  useEffect(() => {
    const fetchEmployeePreviousResponse = async () => {
      const response_id = localStorage.getItem("responseId");
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(
            `${showPrevious360FormsEmployee}?is_employee_360=True&response_id=${response_id}&team_id=${localStorage.getItem(
              "teamId"
            )}`
          )
        );
        setViewResponse(response as ResponseType[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };

    const fetchManagerPreviousResponse = async () => {
      try {
        const response_id = localStorage.getItem("responseId");

        const response = await dispatch2(
          fetchData(
            `${showPrevious360FormsEmployee}?is_manager=True&response_id=${response_id}&team_id=${localStorage.getItem(
              "teamId"
            )}`
          )
        );
        setViewResponse(response as ResponseType[]);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchPeerPreviousResponse = async () => {
      try {
        const response_id = localStorage.getItem("responseId");

        const response = await dispatch2(
          fetchData(
            `${showPrevious360FormsEmployee}?is_peer=True&response_id=${response_id}&team_id=${localStorage.getItem(
              "teamId"
            )}`
          )
        );
        setViewResponse(response as ResponseType[]);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchAdminPreviousResponse = async () => {
      try {
        const response_id = localStorage.getItem("responseId");
        const response = await dispatch2(
          fetchData(
            `${showPrevious360FormsEmployee}?is_organization_leader=True&response_id=${response_id}&team_id=${localStorage.getItem(
              "teamId"
            )}`
          )
        );
        setViewResponse(response as ResponseType[]);
      } catch (error) {
        console.log(error);
      }
    };

    if (roleArray.isEmployee) {
      fetchEmployeePreviousResponse();
    }

    if (roleArray.isManager) {
      fetchManagerPreviousResponse();
    }

    if (roleArray.isPeer) {
      fetchPeerPreviousResponse();
    }

    if (roleArray.isOrganizationLeader) {
      fetchAdminPreviousResponse();
    }
  }, [roleArray]);

  useEffect(() => {
    setRoleArray(
      JSON.parse(
        localStorage.getItem("Role360") ||
          "{isEmployee: false,isManager: false,isOrganizationLeader: false,isPeer: false,}"
      )
    );
  }, []);

  useEffect(() => {
    if (roleArray.isEmployee) {
      setResponseKey("employee_response");
      setCommentKey("employee_comment");
    } else if (roleArray.isManager) {
      setResponseKey("manager_response");
      setCommentKey("manager_comment");
    } else if (roleArray.isOrganizationLeader) {
      setResponseKey("organization_leader_response");
      setCommentKey("organization_leader_comment");
    } else if (roleArray.isPeer) {
      setResponseKey(`${peerNumber}_response` as keyof Question);
      setCommentKey(`${peerNumber}_comment` as keyof Question);
    }
  }, [roleArray]);

  if (inPageLoading) {
    return (
      <CircularProgress
        size={50}
        color="success"
        sx={{ top: 300, left: "50%", position: "relative" }}
      />
    );
  }

  if (isLoading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10, display: "flex", justifyContent: "center" }}>
        <Box>
          {viewResponse?.map((res) => {
            return (
              <Box key={res.form_id} component="form" sx={{ maxWidth: "80vw" }}>
                <FormControl>
                  <Box
                    sx={{
                      mt: 1,
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                      width: "100% ",
                    }}
                  >
                    <Typography
                      color={"#1B87C3"}
                      fontWeight={"bold"}
                      letterSpacing={3}
                      variant="h4"
                      sx={{ textDecoration: "underline" }}
                      id="form_name"
                    >
                      {res.form_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      m: 1,
                      mt: 2,
                      mb: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={"#1B87C3"}
                      id="form_description"
                    >
                      {res.form_description
                        ? `(${res.form_description})`
                        : "(No description)"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      m: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          color={"#1B87C3"}
                          id="appraisee_name"
                        >
                          Appraisee :
                        </Typography>
                        <Typography>{res.employee_name} </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          color={"#1B87C3"}
                          id="manager_name"
                        >
                          Manager Name :{" "}
                        </Typography>
                        <Typography>{res.manager}</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          color={"#1B87C3"}
                          id="employee_code"
                        >
                          Employee Code :{" "}
                        </Typography>
                        <Typography>{res.employee_code} </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          color={"#1B87C3"}
                          id="designation"
                        >
                          Designation :{" "}
                        </Typography>
                        <Typography>{res.employee_designation}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{}}>
                    {res.response?.[0]?.categories.map(
                      (category, categoryIndex) => {
                        return (
                          <Box sx={{ m: 2, width: "75vw" }}>
                            <Accordion
                              sx={{
                                m: 2,
                                p: 0,
                                width: "100%",
                                backgroundColor: "white",
                              }}
                            >
                              <AccordionSummary>
                                <Box>
                                  <Typography
                                    variant="h5"
                                    fontWeight={"bold"}
                                    color={"#1B87C3"}
                                    id={`category_${categoryIndex + 1}`}
                                  >
                                    {String.fromCharCode(
                                      categoryIndex + "a".charCodeAt(0)
                                    ) +
                                      ". " +
                                      category.category}
                                  </Typography>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box sx={{}}>
                                  {/* {section.categories.map(
                                    (category, categoryIndex) => {
                                      return ( */}
                                  <Box>
                                    <Box sx={{ m: 2, p: 2 }}>
                                      {category.questions.map(
                                        (question: any, questionIndex) => {
                                          return (
                                            <Box key={question.question_id}>
                                              <Box>
                                                <Typography
                                                  fontWeight={"bold"}
                                                  fontSize={19}
                                                  variant="h6"
                                                  id={`category_${
                                                    categoryIndex + 1
                                                  }_question_${
                                                    questionIndex + 1
                                                  }`}
                                                >
                                                  {questionIndex + 1 + "."}{" "}
                                                  {question.question_name
                                                    ? question.question_name
                                                    : question.question}
                                                </Typography>
                                              </Box>
                                              <Box sx={{ ml: 2, mr: 2 }}>
                                                {question.answer_type ===
                                                "short_answer" ? (
                                                  <Box>
                                                    <TextField
                                                      id={`employee_response_category__${
                                                        categoryIndex + 1
                                                      }_question_${
                                                        questionIndex + 1
                                                      }`}
                                                      required
                                                      name={`descriptive_${questionIndex}`}
                                                      size="small"
                                                      fullWidth
                                                      color="success"
                                                      multiline
                                                      sx={{
                                                        m: 2,
                                                        overflow: "auto",
                                                      }}
                                                      // disabled
                                                      maxRows={4}
                                                      value={
                                                        question[
                                                          responseKey as keyof Question
                                                        ]
                                                      }
                                                      style={{
                                                        height: "auto",
                                                      }}
                                                      InputProps={{
                                                        style: {
                                                          minHeight: "unset",
                                                        },
                                                      }}
                                                    />
                                                  </Box>
                                                ) : (
                                                  <Box>
                                                    <Box
                                                      sx={{
                                                        display: "block",
                                                        alignItems: "center",
                                                        m: 1,
                                                      }}
                                                    >
                                                      <RadioGroup
                                                        id={`employee_response_category__${
                                                          categoryIndex + 1
                                                        }_question_${
                                                          questionIndex + 1
                                                        }`}
                                                        name={`choices_${questionIndex}`}
                                                        value={
                                                          question[
                                                            responseKey as keyof Question
                                                          ]
                                                        }
                                                      >
                                                        {question.answer_choices &&
                                                          question.answer_choices.map(
                                                            (
                                                              ans: any,
                                                              answerValueIndex: number
                                                            ) => (
                                                              <FormControlLabel
                                                                id={`employee_response_category__${
                                                                  categoryIndex +
                                                                  1
                                                                }_question_${
                                                                  questionIndex +
                                                                  1
                                                                }_answer_value_${
                                                                  answerValueIndex +
                                                                  1
                                                                }`}
                                                                required
                                                                // disabled={
                                                                //   !edit
                                                                // }
                                                                key={
                                                                  ans.option_id
                                                                }
                                                                value={
                                                                  ans.answer_value
                                                                }
                                                                control={
                                                                  <Radio
                                                                    id={`employee_response_category__${
                                                                      categoryIndex +
                                                                      1
                                                                    }_question_${
                                                                      questionIndex +
                                                                      1
                                                                    }_answer_value_${
                                                                      answerValueIndex +
                                                                      1
                                                                    }_radio_button`}
                                                                    color="success"
                                                                  />
                                                                }
                                                                label={
                                                                  ans.answer_value
                                                                }
                                                              />
                                                            )
                                                          )}
                                                      </RadioGroup>
                                                    </Box>
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        m: 1,
                                                        p: 1,
                                                      }}
                                                    >
                                                      <Typography
                                                        fontWeight={"bold"}
                                                        paragraph
                                                        sx={{
                                                          width: "11rem",
                                                        }}
                                                      >
                                                        Comments :{" "}
                                                      </Typography>
                                                      <TextField
                                                        id={`employee_comment_category__${
                                                          categoryIndex + 1
                                                        }_question_${
                                                          questionIndex + 1
                                                        }`}
                                                        name="employee_comment"
                                                        color="success"
                                                        fullWidth
                                                        multiline
                                                        // rows={2}
                                                        // InputProps={{
                                                        //   readOnly: !edit,
                                                        // }}
                                                        maxRows={4}
                                                        style={{
                                                          height: "auto",
                                                          overflow: "auto",
                                                        }}
                                                        value={
                                                          question[
                                                            commentKey as keyof Question
                                                          ]
                                                        }
                                                      />
                                                    </Box>
                                                  </Box>
                                                )}
                                              </Box>
                                            </Box>
                                          );
                                        }
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </FormControl>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default ThreeSixtySpecificPreviousResponse;
