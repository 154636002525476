import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

import {
  editEmployeeSelfResponse,
  finalSubmit,
  managerFinalSubmit,
  managerReview,
  organizationLeaderFinalSubmit,
  organizationLeaderReviewSave,
  peerFinalSubmit,
  peerReviewSave,
  showOrganizationLeaderAssigned360Form,
  showPeerAssigned360Form,
  viewEmployeeSelfResponse,
  viewResponse360Manager,
} from "../../../Service/Apis";
import Footer from "../../../components/Page Component/Footer";
import Navbar from "../../../components/Page Component/Navbar";

import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  ToastVariant,
  useCustomToast,
} from "../../../components/Methods/SnackBar";
import Skeletons from "../../../components/Skeleton/Skeletons";
import { postDataSuccess } from "../../../store/AxiosSlice";
import { setInPageLoading, setIsLoading } from "../../../store/LoadingSlice";
import { RootState } from "../../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  patchData,
} from "../../../store/actions";

interface ResponseType {
  response_id: string;
  form_team_id: string;
  form_id: string;
  form_name: string;
  form_description: string;
  team_id: string;
  project_name: string;
  manager: string;
  employee_id: string;
  roles: string[];
  employee_name: string;
  employee_deadline: string;
  employee_code: string;
  employee_designation: string;
  designation: string;
  manager_deadline: string;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  response: Section[]; // Update this to use Section[] instead of [{...}]
}

interface Response1 {
  section_id: string;
  category_id: string;
  question_id: string;
  employee_response: string;
  employee_comment: string;
  employee_rating: string;
  manager_response: string;
  manager_comment: string;
  organization_leader_response: string;
  organization_leader_comment: string;
  peer_one_response: string;
  peer_one_comment: string;
  peer_two_response: string;
  peer_two_comment: string;
  peer_three_response: string;
  peer_three_comment: string;
  peer_four_response: string;
  peer_four_comment: string;
  peer_five_response: string;
  peer_five_comment: string;
}

interface Section {
  section_id: string;
  section: string;
  categories: Category[];
}

interface Category {
  category_id: string;
  category: string;
  questions: Question[];
}

interface Question {
  question_id: string;
  question_name: string;
  employee_rating: string;
  employee_response: string;
  employee_comment: string;
  manager_response: string;
  manager_comment: string;
  organization_leader_response: string;
  organization_leader_comment: string;
  peer_one_response: string;
  peer_one_comment: string;
  peer_two_response: string;
  peer_two_comment: string;
  peer_three_response: string;
  peer_three_comment: string;
  peer_four_response: string;
  peer_four_comment: string;
  peer_five_response: string;
  peer_five_comment: string;
  answer_type: string;
  answer_choices: {
    option_id: string;
    answer_value: string;
  }[];
}

interface SpecialCharErrors {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface MinCharacterError {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface SpacesErrors {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

const initialSpecialCharErrors: SpecialCharErrors = {};
const initialMinCharacterErrors: MinCharacterError = {};
const initialSpacesErrors: SpacesErrors = {};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ThreeSixtyFormFill = () => {
  const [viewResponse, setViewResponse] = useState<ResponseType[]>([]);
  const [specialCharErrors, setSpecialCharErrors] = useState<SpecialCharErrors>(
    initialSpecialCharErrors
  );

  const [spacesErrors, setSpacesErrors] =
    useState<SpacesErrors>(initialSpacesErrors);
  const [minCharacterError, setMinCharacterError] = useState<MinCharacterError>(
    initialMinCharacterErrors
  );
  const [flag, setFlag] = useState<boolean>(false);
  const [flag2, setFlag2] = useState<boolean>(false);
  const [empRating, setEmpRating] = useState<boolean[][][]>([]);
  const [isSpecialCharError, setIsSpecialCharError] = useState<boolean>(false);
  const [isSpacesError, setIsSpacesError] = useState<boolean>(false);
  const [isMinCharacterError, setIsMinCharacterError] =
    useState<boolean>(false);
  const [roleArray, setRoleArray] = useState({
    isEmployee: false,
    isManager: false,
    isOrganizationLeader: false,
    isPeer: false,
  });
  const [responseKey, setResponseKey] = useState<keyof Question>();
  const [commentKey, setCommentKey] = useState<keyof Question>();

  const showToast = useCustomToast();

  const Navigate = useNavigate();

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  // const success = useSelector((state: any) => state.http.data);
  const peerNumber = localStorage.getItem("peerNumber");

  const formId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const formTeamId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const dispatch = useDispatch();
  dispatch(setIsLoading(false));

  const isLoading = useSelector((state: any) => state.loading.isLoading);
  const inPageLoading = useSelector(
    (state: any) => state.loading.inPageLoading
  );

  const hasSpecialCharacters = (input: string): boolean => {
    const specialCharacterPattern = /[#^*_+\=\;:"\\|<>/]/;
    return specialCharacterPattern.test(input);
  };

  const handleChange = (
    categoryIndex: number,
    questionIndex: number,
    section_id: string,
    category_id: string,
    question_id: string,
    field: keyof Question,
    value: string,
    answer_type: string
  ): void => {
    const containsOnlySpaces = /^\s*$/.test(value);

    setViewResponse((prevResponse) => {
      // Create a new copy of the response array to update it
      const updatedResponse: ResponseType[] = prevResponse.map(
        (item, itemIndex) => {
          if (itemIndex === 0) {
            // Update only the first element (index 0) of the array
            const updatedSections: Section[] = item.response.map(
              (section, sIndex) => {
                if (sIndex === 0) {
                  // Update the specific section
                  const updatedCategories: Category[] = section.categories.map(
                    (category, cIndex) => {
                      if (cIndex === categoryIndex) {
                        // Update the specific category
                        const updatedQuestions: Question[] =
                          category.questions.map((question, qIndex) => {
                            if (qIndex === questionIndex) {
                              // Update the specific question
                              return {
                                ...question,
                                [field]: value ? value : "",
                                question_id,
                              };
                            }
                            return question;
                          });

                        return {
                          ...category,
                          category_id,
                          questions: updatedQuestions,
                        };
                      }
                      return category;
                    }
                  );

                  return {
                    ...section,
                    section_id,
                    categories: updatedCategories,
                  };
                }
                return section;
              }
            );

            return {
              ...item,
              response: updatedSections,
            };
          }
          return item;
        }
      );

      return updatedResponse;
    });

    const updatedSpecialCharErrors = { ...specialCharErrors };
    const sectionErrors = updatedSpecialCharErrors[0] || {};
    const categoryErrors = sectionErrors[categoryIndex] || {};
    const questionErrors = categoryErrors[questionIndex] || {
      employee_response: false,
      employee_comment: false,
    };

    const updatedSpacesErrors = { ...spacesErrors };
    const sectionSpacesErrors = updatedSpacesErrors[0] || {};
    const categorySpacesErrors = sectionSpacesErrors[categoryIndex] || {};
    const questionSpacesErrors = categorySpacesErrors[questionIndex] || {
      employee_response: false,
    };

    const updatedMinCharacterError = { ...minCharacterError };
    const sectionMinCharatcerError = updatedMinCharacterError[0] || {};
    const categoryMinCharacterErorr =
      sectionMinCharatcerError[categoryIndex] || {};
    const questionMinCharacterError = categoryMinCharacterErorr[
      questionIndex
    ] || {
      // employee_comment: false,
      employee_response: false,
    };

    // Check for special characters in employee_response and employee_comment
    if (field === "employee_response") {
      if (answer_type === "short_answer") {
        questionErrors.employee_response = hasSpecialCharacters(value);
      }
      questionSpacesErrors.employee_response = containsOnlySpaces;
      if (answer_type === "short_answer") {
        questionMinCharacterError.employee_response = value.length < 3;
      }
    } else if (field === "employee_comment") {
      questionErrors.employee_comment = hasSpecialCharacters(value);
      // questionMinCharacterError.employee_comment = value.length < 3;
    }

    // Update the specialCharErrors state
    categoryErrors[questionIndex] = questionErrors;
    sectionErrors[categoryIndex] = categoryErrors;
    updatedSpecialCharErrors[0] = sectionErrors;

    setSpecialCharErrors(updatedSpecialCharErrors);

    categorySpacesErrors[questionIndex] = questionSpacesErrors;
    sectionSpacesErrors[categoryIndex] = categorySpacesErrors;
    updatedSpacesErrors[0] = sectionSpacesErrors;

    setSpacesErrors(updatedSpacesErrors);

    categoryMinCharacterErorr[questionIndex] = questionMinCharacterError;
    sectionMinCharatcerError[categoryIndex] = categoryMinCharacterErorr;
    updatedMinCharacterError[0] = sectionMinCharatcerError;

    setMinCharacterError(updatedMinCharacterError);

    // Check for any Special Character Error.
    let isAnySpecialCharError = false;
    Object.values(updatedSpecialCharErrors).forEach((categories) => {
      Object.values(categories).forEach((questions) => {
        Object.values(questions).forEach((error) => {
          if (error.employee_response || error.employee_comment) {
            isAnySpecialCharError = true;
          }
        });
      });
    });
    setIsSpecialCharError(isAnySpecialCharError);
  };

  // Check for spaces errors.
  const validateSpacesError = () => {
    const containsOnlySpaces = /^\s*$/;
    viewResponse[0].response.forEach((section, sectionIndex) => {
      section.categories.forEach((category, categoryIndex) => {
        category.questions.forEach((question, questionIndex) => {
          const { employee_response, employee_comment } = question;

          if (
            (employee_response && employee_response.trim().length === 0) ||
            containsOnlySpaces.test(employee_response)
          ) {
            if (!spacesErrors[sectionIndex]) {
              spacesErrors[sectionIndex] = {};
            }
            if (!spacesErrors[sectionIndex][categoryIndex]) {
              spacesErrors[sectionIndex][categoryIndex] = {};
            }
            spacesErrors[sectionIndex][categoryIndex][questionIndex] = {
              employee_response: true,
              employee_comment: false,
            };
            setIsSpacesError(true);
          }
        });
      });
    });
  };

  // check for minimum character error.
  const validateMinCharacters = () => {
    viewResponse[0].response.forEach((section, sectionIndex) => {
      section.categories.forEach((category, categoryIndex) => {
        category.questions.forEach((question, questionIndex) => {
          const { employee_response, employee_comment, answer_type } = question;

          if (
            employee_response &&
            employee_response.trim().length < 3 &&
            answer_type === "short_answer"
          ) {
            if (!minCharacterError[sectionIndex]) {
              minCharacterError[sectionIndex] = {};
            }
            if (!minCharacterError[sectionIndex][categoryIndex]) {
              minCharacterError[sectionIndex][categoryIndex] = {};
            }
            minCharacterError[sectionIndex][categoryIndex][questionIndex] = {
              employee_response: true,
              employee_comment: false,
            };
            setIsMinCharacterError(true);
          }
        });
      });
    });
  };

  const handleSubmit = async (e: any, form_team_id: string) => {
    e.preventDefault();

    validateMinCharacters();
    validateSpacesError();

    let isMinCharacterErrors = false;

    Object.values(minCharacterError).forEach((categories) => {
      Object.values(categories).forEach((questions: any) => {
        Object.values(questions).forEach((error: any) => {
          if (error.employee_response) {
            isMinCharacterErrors = true;
          }
        });
      });
    });

    let isSpacesError = false;

    Object.values(spacesErrors).forEach((categories) => {
      Object.values(categories).forEach((questions: any) => {
        Object.values(questions).forEach((error: any) => {
          if (error.employee_response) {
            isSpacesError = true;
          }
        });
      });
    });

    // Now you can use isEveryEmpRatingTrue to conditionally render content or take any other action based on the empRating state.
    // if (isSpacesError || isMinCharacterErrors) {
    //   setFlag(true);
    //   setFlag2(true);

    //   handleShowToast("Please fill all details", "error", true);
    //   return;
    // }
    handleSave(e);

    // API call to submit the form.
    if (roleArray.isEmployee) {
      try {
        const response = await dispatch2(
          patchData(`${finalSubmit}${viewResponse[0]?.response_id}`, {})
        )
          .then((response) => {
            dispatch(setInPageLoading(true));
            handleShowToast("Form Submitted Successfully !", "success", true);
            dispatch(postDataSuccess(null));
            dispatch(setInPageLoading(false));
            Navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
            dispatch(setInPageLoading(false));
          });
      } catch (error) {
        console.log(error);
      }
    } else if (roleArray.isManager) {
      try {
        const response = await dispatch2(
          patchData(`${managerFinalSubmit}${viewResponse[0]?.response_id}`, {})
        )
          .then((response) => {
            dispatch(setInPageLoading(true));
            handleShowToast("Form Submitted Successfully !", "success", true);
            dispatch(postDataSuccess(null));
            dispatch(setInPageLoading(false));
            Navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
            dispatch(setInPageLoading(false));
          });
      } catch (error) {
        console.log(error);
      }
    } else if (roleArray.isOrganizationLeader) {
      try {
        const response = await dispatch2(
          patchData(
            `${organizationLeaderFinalSubmit}${viewResponse[0]?.response_id}`,
            {}
          )
        )
          .then((response) => {
            dispatch(setInPageLoading(true));
            handleShowToast("Form Submitted Successfully !", "success", true);
            dispatch(postDataSuccess(null));
            dispatch(setInPageLoading(false));
            Navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
            dispatch(setInPageLoading(false));
          });
      } catch (error) {
        console.log(error);
      }
    } else if (roleArray.isPeer) {
      try {
        const response = await dispatch2(
          patchData(
            `${peerFinalSubmit}${viewResponse[0]?.response_id}&peer=${peerNumber}`,
            {}
          )
        )
          .then((response) => {
            dispatch(setInPageLoading(true));
            handleShowToast("Form Submitted Successfully !", "success", true);
            dispatch(postDataSuccess(null));
            dispatch(setInPageLoading(false));
            Navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
            dispatch(setInPageLoading(false));
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    dispatch(setInPageLoading(true));
    const isEveryEmpRatingTrue = empRating.every((section) => {
      return section.every((category) => {
        return category.every((questionEmpRating) => {
          return questionEmpRating === true;
        });
      });
    });

    // Convert the data into the specific format that goes to API.
    const responseToArray = (response: ResponseType): Response1[] => {
      const peerNumber = localStorage.getItem("peerNumber");
      const responseArray: Response1[] = [];

      response.response.forEach((section) => {
        if (section) {
          const { section_id } = section;

          section.categories.forEach((category) => {
            if (category) {
              const { category_id } = category;

              category.questions.forEach((question) => {
                if (question) {
                  const {
                    question_id,
                    employee_response,
                    employee_comment,
                    employee_rating,
                    manager_response,
                    manager_comment,
                    organization_leader_comment,
                    organization_leader_response,
                    peer_one_comment,
                    peer_one_response,
                    peer_two_response,
                    peer_two_comment,
                    peer_three_response,
                    peer_three_comment,
                    peer_four_response,
                    peer_four_comment,
                    peer_five_response,
                    peer_five_comment,
                  } = question;

                  let payload: Partial<Response1> = {
                    section_id,
                    category_id,
                    question_id,
                  };

                  if (roleArray.isEmployee) {
                    payload.employee_response = employee_response || "";
                    payload.employee_comment = employee_comment || "";
                    payload.employee_rating = ""; // You may need to adjust this based on your requirements
                  }

                  if (roleArray.isManager) {
                    payload.manager_response = manager_response || "";
                    payload.manager_comment = manager_comment || "";
                  }

                  if (roleArray.isOrganizationLeader) {
                    payload.organization_leader_response =
                      organization_leader_response || "";
                    payload.organization_leader_comment =
                      organization_leader_comment || "";
                  }

                  if (roleArray.isPeer && peerNumber) {
                    // Determine the specific peer based on peerNumber value
                    switch (peerNumber) {
                      case "peer_one":
                        payload[`${peerNumber}_response`] =
                          peer_one_response || "";
                        payload[`${peerNumber}_comment`] =
                          peer_one_comment || "";
                        break;
                      case "peer_two":
                        payload[`${peerNumber}_response`] =
                          peer_two_response || "";
                        payload[`${peerNumber}_comment`] =
                          peer_two_comment || "";
                        break;
                      case "peer_three":
                        payload[`${peerNumber}_response`] =
                          peer_three_response || "";
                        payload[`${peerNumber}_comment`] =
                          peer_three_comment || "";
                        break;
                      case "peer_four":
                        payload[`${peerNumber}_response`] =
                          peer_four_response || "";
                        payload[`${peerNumber}_comment`] =
                          peer_four_comment || "";
                        break;
                      case "peer_five":
                        payload[`${peerNumber}_response`] =
                          peer_five_response || "";
                        payload[`${peerNumber}_comment`] =
                          peer_five_comment || "";
                        break;
                      default:
                        break;
                    }
                  }

                  responseArray.push(payload as Response1);
                }
              });
            }
          });
        }
      });

      return responseArray;
    };

    const data = responseToArray(viewResponse[0]);

    const response_id = viewResponse[0]?.response_id;

    // API call to update  the form response.
    if (roleArray.isEmployee) {
      try {
        const response = await dispatch2(
          patchData(`${editEmployeeSelfResponse}${response_id}`, data)
        )
          .then((response) => {
            handleShowToast("Updated Successfully!", "success", false);
            dispatch(postDataSuccess(null));
            Navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else if (roleArray.isManager) {
      try {
        const response = dispatch2(
          patchData(`${managerReview}${response_id}`, data)
        )
          .then((response) => {
            handleShowToast("Response Saved", "success", true);
            dispatch(postDataSuccess(null));

            Navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else if (roleArray.isOrganizationLeader) {
      try {
        const response = dispatch2(
          patchData(`${organizationLeaderReviewSave}${response_id}`, data)
        )
          .then((response) => {
            handleShowToast("Response Saved", "success", true);
            dispatch(postDataSuccess(null));

            Navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else if (roleArray.isPeer) {
      try {
        const response = dispatch2(
          patchData(`${peerReviewSave}${response_id}`, data)
        )
          .then((response) => {
            handleShowToast("Response Saved", "success", true);
            dispatch(postDataSuccess(null));

            Navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }

    dispatch(setInPageLoading(false));
  };

  // API call to view specific response of the employee.
  useEffect(() => {
    const fetchEmployeeSelfResponse = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${viewEmployeeSelfResponse}${formId}`)
        );
        setViewResponse(response as ResponseType[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };

    const fetchManagerSelfResponse = async () => {
      try {
        const response_id = localStorage.getItem("responseId");

        const response = await dispatch2(
          fetchData(`${viewResponse360Manager}?response_id=${response_id}`)
        );
        setViewResponse(response as ResponseType[]);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchPeerSelfResponse = async () => {
      try {
        const response_id = localStorage.getItem("responseId");

        const response = await dispatch2(
          fetchData(
            `${showPeerAssigned360Form}?response_id=${response_id}&team_id=${localStorage.getItem(
              "teamId"
            )}`
          )
        );
        setViewResponse(response as ResponseType[]);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchAdminSelfResponse = async () => {
      try {
        const response_id = localStorage.getItem("responseId");

        const response = await dispatch2(
          fetchData(
            `${showOrganizationLeaderAssigned360Form}?response_id=${response_id}`
          )
        );
        setViewResponse(response as ResponseType[]);
      } catch (error) {
        console.log(error);
      }
    };

    if (roleArray.isEmployee) {
      fetchEmployeeSelfResponse();
    }

    if (roleArray.isManager) {
      fetchManagerSelfResponse();
    }

    if (roleArray.isPeer) {
      fetchPeerSelfResponse();
    }

    if (roleArray.isOrganizationLeader) {
      fetchAdminSelfResponse();
    }
  }, [roleArray]);

  useEffect(() => {
    setRoleArray(
      JSON.parse(
        localStorage.getItem("Role360") ||
          "{isEmployee: false,isManager: false,isOrganizationLeader: false,isPeer: false,}"
      )
    );
  }, []);

  useEffect(() => {
    if (roleArray.isEmployee) {
      setResponseKey("employee_response");
      setCommentKey("employee_comment");
    } else if (roleArray.isManager) {
      setResponseKey("manager_response");
      setCommentKey("manager_comment");
    } else if (roleArray.isOrganizationLeader) {
      setResponseKey("organization_leader_response");
      setCommentKey("organization_leader_comment");
    } else if (roleArray.isPeer) {
      setResponseKey(`${peerNumber}_response` as keyof Question);
      setCommentKey(`${peerNumber}_comment` as keyof Question);
    }
  }, [roleArray]);

  if (inPageLoading) {
    return (
      <CircularProgress
        size={50}
        color="success"
        sx={{ top: 300, left: "50%", position: "relative" }}
      />
    );
  }

  if (isLoading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10, display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "75%" }}>
          {viewResponse?.map((res) => {
            return (
              <Box
                key={res.form_id}
                component="form"
                onSubmit={(e) => {
                  handleSubmit(e, res.form_team_id);
                }}
              >
                <FormControl>
                  <Box
                    sx={{
                      mt: 1,
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                      width: "100% ",
                    }}
                  >
                    <Typography
                      color={"#1B87C3"}
                      fontWeight={"bold"}
                      letterSpacing={3}
                      variant="h4"
                      sx={{ textDecoration: "underline" }}
                      id="form_name"
                    >
                      {res.form_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      m: 1,
                      mt: 2,
                      mb: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={"#1B87C3"}
                      id="form_description"
                    >
                      {res.form_description
                        ? `(${res.form_description})`
                        : "(No description)"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      m: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          color={"#1B87C3"}
                          id="appraisee_name"
                        >
                          Appraisee :
                        </Typography>
                        <Typography>{res.employee_name} </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          color={"#1B87C3"}
                          id="manager_name"
                        >
                          Manager Name :{" "}
                        </Typography>
                        <Typography>{res.manager}</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          color={"#1B87C3"}
                          id="employee_code"
                        >
                          Employee Code :{" "}
                        </Typography>
                        <Typography>{res.employee_code} </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          color={"#1B87C3"}
                          id="designation"
                        >
                          Designation :{" "}
                        </Typography>
                        <Typography>
                          {res.employee_designation
                            ? res.employee_designation
                            : res.designation}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{}}>
                    {res.response?.[0]?.categories.map(
                      (category, categoryIndex) => {
                        return (
                          <Box sx={{ m: 2, width: "75vw" }}>
                            <Accordion
                              sx={{
                                m: 2,
                                p: 0,
                                width: "100%",
                                backgroundColor: "white",
                              }}
                            >
                              <AccordionSummary>
                                <Box>
                                  <Typography
                                    variant="h5"
                                    fontWeight={"bold"}
                                    color={"#1B87C3"}
                                    id={`category_${categoryIndex + 1}`}
                                  >
                                    {String.fromCharCode(
                                      categoryIndex + "a".charCodeAt(0)
                                    ) +
                                      ". " +
                                      category.category}
                                  </Typography>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box sx={{}}>
                                  {/* {section.categories.map(
                                    (category, categoryIndex) => {
                                      return ( */}
                                  <Box>
                                    <Box sx={{ m: 2, p: 2 }}>
                                      {category.questions.map(
                                        (question: any, questionIndex) => {
                                          return (
                                            <Box key={question.question_id}>
                                              <Box>
                                                <Typography
                                                  fontWeight={"bold"}
                                                  fontSize={19}
                                                  variant="h6"
                                                  id={`category_${
                                                    categoryIndex + 1
                                                  }_question_${
                                                    questionIndex + 1
                                                  }`}
                                                >
                                                  {questionIndex + 1 + "."}{" "}
                                                  {question.question_name
                                                    ? question.question_name
                                                    : question.question}
                                                </Typography>
                                              </Box>
                                              <Box sx={{ ml: 2, mr: 2 }}>
                                                {question.answer_type ===
                                                "short_answer" ? (
                                                  <Box>
                                                    <TextField
                                                      id={`employee_response_category__${
                                                        categoryIndex + 1
                                                      }_question_${
                                                        questionIndex + 1
                                                      }`}
                                                      required
                                                      name={`descriptive_${questionIndex}`}
                                                      size="small"
                                                      fullWidth
                                                      color="success"
                                                      multiline
                                                      sx={{
                                                        m: 2,
                                                        overflow: "auto",
                                                      }}
                                                      // disabled
                                                      maxRows={4}
                                                      value={
                                                        question[
                                                          responseKey as keyof Question
                                                        ]
                                                      }
                                                      style={{
                                                        height: "auto",
                                                      }}
                                                      InputProps={{
                                                        style: {
                                                          minHeight: "unset",
                                                        },
                                                      }}
                                                      onChange={(e) => {
                                                        handleChange(
                                                          categoryIndex,
                                                          questionIndex,
                                                          res.response[0]
                                                            .section_id,
                                                          category.category_id,
                                                          question.question_id,
                                                          responseKey as keyof Question,
                                                          e.target.value,
                                                          question.answer_type
                                                        );
                                                      }}
                                                      helperText={
                                                        <>
                                                          {specialCharErrors[0]?.[
                                                            categoryIndex
                                                          ]?.[questionIndex]
                                                            ?.employee_response && (
                                                            <Typography
                                                              color={"error"}
                                                            >
                                                              Special Characters
                                                              not allowed.
                                                            </Typography>
                                                          )}
                                                          {spacesErrors[0]?.[
                                                            categoryIndex
                                                          ]?.[questionIndex]
                                                            ?.employee_response &&
                                                            flag && (
                                                              <Typography
                                                                color={"error"}
                                                              >
                                                                Only Spaces not
                                                                allowed.
                                                              </Typography>
                                                            )}
                                                          {minCharacterError[0]?.[
                                                            categoryIndex
                                                          ]?.[questionIndex]
                                                            ?.employee_response &&
                                                            flag2 && (
                                                              <Typography
                                                                color={"error"}
                                                              >
                                                                Minimum 3
                                                                characters
                                                                required.
                                                              </Typography>
                                                            )}
                                                        </>
                                                      }
                                                    />
                                                  </Box>
                                                ) : (
                                                  <Box>
                                                    <Box
                                                      sx={{
                                                        display: "block",
                                                        alignItems: "center",
                                                        m: 1,
                                                      }}
                                                    >
                                                      <RadioGroup
                                                        id={`employee_response_category__${
                                                          categoryIndex + 1
                                                        }_question_${
                                                          questionIndex + 1
                                                        }`}
                                                        name={`choices_${questionIndex}`}
                                                        value={
                                                          question[
                                                            responseKey as keyof Question
                                                          ]
                                                        }
                                                        onChange={(e) => {
                                                          handleChange(
                                                            categoryIndex,
                                                            questionIndex,
                                                            res.response[0]
                                                              .section_id,
                                                            category.category_id,
                                                            question.question_id,
                                                            responseKey as keyof Question,
                                                            e.target.value,
                                                            question.answer_type
                                                          );
                                                        }}
                                                      >
                                                        {question.answer_choices &&
                                                          question.answer_choices.map(
                                                            (
                                                              ans: any,
                                                              answerValueIndex: number
                                                            ) => (
                                                              <FormControlLabel
                                                                id={`employee_response_category__${
                                                                  categoryIndex +
                                                                  1
                                                                }_question_${
                                                                  questionIndex +
                                                                  1
                                                                }_answer_value_${
                                                                  answerValueIndex +
                                                                  1
                                                                }`}
                                                                required
                                                                // disabled={
                                                                //   !edit
                                                                // }
                                                                key={
                                                                  ans.option_id
                                                                }
                                                                value={
                                                                  ans.answer_value
                                                                }
                                                                control={
                                                                  <Radio
                                                                    id={`employee_response_category__${
                                                                      categoryIndex +
                                                                      1
                                                                    }_question_${
                                                                      questionIndex +
                                                                      1
                                                                    }_answer_value_${
                                                                      answerValueIndex +
                                                                      1
                                                                    }_radio_button`}
                                                                    color="success"
                                                                  />
                                                                }
                                                                label={
                                                                  ans.answer_value
                                                                }
                                                              />
                                                            )
                                                          )}
                                                      </RadioGroup>
                                                    </Box>
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        m: 1,
                                                        p: 1,
                                                      }}
                                                    >
                                                      <Typography
                                                        fontWeight={"bold"}
                                                        paragraph
                                                        sx={{
                                                          width: "11rem",
                                                        }}
                                                      >
                                                        Comments :{" "}
                                                      </Typography>
                                                      <TextField
                                                        id={`employee_comment_category__${
                                                          categoryIndex + 1
                                                        }_question_${
                                                          questionIndex + 1
                                                        }`}
                                                        name="employee_comment"
                                                        color="success"
                                                        fullWidth
                                                        multiline
                                                        // rows={2}
                                                        // InputProps={{
                                                        //   readOnly: !edit,
                                                        // }}
                                                        maxRows={4}
                                                        style={{
                                                          height: "auto",
                                                          overflow: "auto",
                                                        }}
                                                        value={
                                                          question[
                                                            commentKey as keyof Question
                                                          ]
                                                        }
                                                        onChange={(e) => {
                                                          handleChange(
                                                            categoryIndex,
                                                            questionIndex,
                                                            res.response[0]
                                                              .section_id,
                                                            category.category_id,
                                                            question.question_id,
                                                            commentKey as keyof Question,
                                                            e.target.value,
                                                            question.answer_type
                                                          );
                                                        }}
                                                        helperText={
                                                          <>
                                                            {specialCharErrors[0]?.[
                                                              categoryIndex
                                                            ]?.[questionIndex]
                                                              ?.employee_comment && (
                                                              <Typography
                                                                color={"error"}
                                                              >
                                                                Special
                                                                Characters not
                                                                allowed.
                                                              </Typography>
                                                            )}
                                                            {minCharacterError[0]?.[
                                                              categoryIndex
                                                            ]?.[questionIndex]
                                                              ?.employee_comment && (
                                                              <Typography
                                                                color={"error"}
                                                              >
                                                                Minimum 3
                                                                characters
                                                                required.
                                                              </Typography>
                                                            )}
                                                          </>
                                                        }
                                                      />
                                                    </Box>
                                                  </Box>
                                                )}
                                              </Box>
                                            </Box>
                                          );
                                        }
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      p: 1,
                      m: 1,
                    }}
                  >
                    <Button
                      id="save_button"
                      color="success"
                      size="large"
                      variant="outlined"
                      onClick={(e) => {
                        handleSave(e);
                      }}
                      disabled={isSpecialCharError}
                      sx={{ m: 1 }}
                    >
                      {" "}
                      Save
                    </Button>
                    <Button
                      id="mark_for_submission_button"
                      type="submit"
                      color="success"
                      size="large"
                      variant="contained"
                      disabled={isSpecialCharError}
                      sx={{ m: 1 }}
                    >
                      Mark for Submission
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default ThreeSixtyFormFill;
