import {
  AccordionProps,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewAllResponses, showTeams, viewUsers } from "../../../Service/Apis";
import Footer from "../../../components/Page Component/Footer";
import Navbar from "../../../components/Page Component/Navbar";
import { setInPageLoading, setIsLoading } from "../../../store/LoadingSlice";
import { RootState } from "../../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../../store/actions";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import NothingToDisplayComponent from "../../../components/Data/NothingToShowComponent";
import { useNavigate } from "react-router-dom";

const initialStateEmployee = {
  id: "",
  employee_code: "",
  first_name: "",
  last_name: "",
  gender: "",
  roles: [],
  designation: "",
  date_of_birth: "",
  date_of_joining: "",
  date_of_confirmation: "",
  date_of_exit: "",
  email: "",
  password: "",
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface ResponseType {
  response_id: string;
  form_team_id: string;
  form_id: string;
  form_name: string;
  form_description: string;
  team_id: string;
  project_name: string;
  manager_name: string;
  employee_id: string;
  roles: string[];
  employee_name: string;
  employee_deadline: string;
  employee_code: string;
  employee_designation: string;
  manager_deadline: string;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  final_submit_done: boolean;
  manager_final_submit_done: boolean;
  admin_final_submit_done: boolean;
  peer_one_final_submit_done: boolean;
  peer_two_final_submit_done: boolean;
  peer_three_final_submit_done: boolean;
  peer_four_final_submit_done: boolean;
  peer_five_final_submit_done: boolean;
  response: Section[]; // Update this to use Section[] instead of [{...}]
}

interface Response1 {
  section_id: string;
  category_id: string;
  question_id: string;
  employee_response: string;
  employee_comment: string;
  employee_rating: string;
  manager_response: string;
  manager_comment: string;
  organization_leader_response: string;
  organization_leader_comment: string;
  peer_one_response: string;
  peer_one_comment: string;
  peer_two_response: string;
  peer_two_comment: string;
  peer_three_response: string;
  peer_three_comment: string;
  peer_four_response: string;
  peer_four_comment: string;
  peer_five_response: string;
  peer_five_comment: string;
}

interface Section {
  section_id: string;
  section: string;
  categories: Category[];
}

interface Category {
  category_id: string;
  category: string;
  questions: Question[];
}

interface Question {
  question_id: string;
  question_name: string;
  employee_rating: string;
  employee_response: string;
  employee_comment: string;
  manager_response: string;
  manager_comment: string;
  organization_leader_response: string;
  organization_leader_comment: string;
  peer_one_response: string;
  peer_one_comment: string;
  peer_two_response: string;
  peer_two_comment: string;
  peer_three_response: string;
  peer_three_comment: string;
  peer_four_response: string;
  peer_four_comment: string;
  peer_five_response: string;
  peer_five_comment: string;
  answer_type: string;
  answer_choices: {
    option_id: string;
    answer_value: string;
  }[];
}

const ShowThreeSixtyFormReview = () => {
  const [viewResponse, setViewResponse] = useState<ResponseType[]>([]);
  const [teamDetails, setTeamDetails] = useState<any[]>([]); //for storing team details
  const [arrayOfId, setArrayOfId] = useState<string[]>([]);
  const [getEmployee, setGetEmployee] = useState<
    (typeof initialStateEmployee)[]
  >([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [responseKey, setResponseKey] =
    useState<keyof Question>("employee_response");
  const [commentKey, setCommentKey] =
    useState<keyof Question>("employee_comment");
  const [finalSubmitDoneKey, setFinalSubmitDoneKey] =
    useState<keyof ResponseType>("final_submit_done");

  const isloading = useSelector((state: any) => state.loading.inPageLoading);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const arrayOfIds =
    teamDetails.length > 0
      ? teamDetails
          .map(
            ({
              employee_360,
              manager,
              organization_leader,
              peer_one,
              peer_two,
              peer_three,
              peer_four,
              peer_five,
            }) => [
              { id: employee_360, role: "employee" },
              { id: manager, role: "manager" },
              { id: organization_leader, role: "organization_leader" },
              { id: peer_one, role: "peer_one" },
              { id: peer_two, role: "peer_two" },
              { id: peer_three, role: "peer_three" },
              { id: peer_four, role: "peer_four" },
              { id: peer_five, role: "peer_five" },
              // ... other values
            ]
          )
          .flat()
          .filter((employee) => employee.id)
      : [];
  // }, [teamDetails]);

  const usersWithFilter = useMemo(() => {
    return getEmployee.filter((employee) =>
      arrayOfIds.some((idObj: any) => idObj.id === employee.id)
    );
  }, [getEmployee]);

  const handleChange = (event: any, newValue: any) => {
    // newValue is the selected user object
    setSelectedUser(newValue);
    const role = arrayOfIds.filter((emp) => emp?.id === newValue?.id)[0]?.role;
    setResponseKey(`${role}_response` as keyof Question);
    setCommentKey(`${role}_comment` as keyof Question);
    if (role === "employee") {
      setFinalSubmitDoneKey(`final_submit_done` as keyof ResponseType);
    } else if (role === "organization_leader") {
      setFinalSubmitDoneKey(`admin_final_submit_done` as keyof ResponseType);
    } else {
      setFinalSubmitDoneKey(`${role}_final_submit_done` as keyof ResponseType);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const fetchUserDetails = async () => {
        try {
          dispatch(setInPageLoading(true));
          const response = await dispatch2(fetchData(`${viewUsers}`));
          setGetEmployee(response as (typeof initialStateEmployee)[]);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch(setInPageLoading(false));
        }
      };

      const fetchTeamsData = async () => {
        try {
          dispatch(setInPageLoading(true));
          const response = await dispatch2(
            fetchData(`${showTeams}?team_id=${localStorage.getItem("teamId")}`)
          );
          setTeamDetails(response as any[]);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch(setInPageLoading(false));
        }
      };

      const fetchResponses = async () => {
        try {
          dispatch(setIsLoading(true));
          const response = await dispatch2(
            fetchData(
              `${ViewAllResponses}?response_id=${localStorage.getItem(
                "responseId"
              )}`
            )
          );
          setViewResponse(response as ResponseType[]);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch(setIsLoading(false));
        }
      };

      await fetchTeamsData();
      await fetchUserDetails();
      await fetchResponses();
    };

    fetchDetails();
  }, []);

  if (isloading) {
    return (
      <>
        <CircularProgress
          size={50}
          color="success"
          sx={{ top: 300, left: "50%", position: "relative" }}
        />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 12,
          mb: 10,
        }}
      >
        <Box sx={{ m: 2, p: 2, width: "75%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              m: 2,
            }}
          >
            <InputLabel sx={{ width: "10rem" }}>Select Employee :</InputLabel>
            <Autocomplete
              sx={{ width: "15rem" }}
              size="small"
              options={usersWithFilter}
              getOptionLabel={(user) => user.first_name + " " + user.last_name}
              value={selectedUser}
              onChange={handleChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Box>
          {selectedUser !== null && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {viewResponse?.map((res) => {
                if (res[finalSubmitDoneKey]) {
                  return (
                    <Box
                      key={res.form_id}
                      component="form"
                      onSubmit={(e) => {
                        // handleSubmit(e, res.form_team_id);
                      }}
                      sx={{ maxWidth: "80vw" }}
                    >
                      <FormControl>
                        <Box
                          sx={{
                            mt: 1,
                            pt: 2,
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            color={"#1B87C3"}
                            fontWeight={"bold"}
                            letterSpacing={3}
                            variant="h4"
                            sx={{ textDecoration: "underline" }}
                            id="form_name"
                          >
                            {res.form_name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            m: 1,
                            mt: 2,
                            mb: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            color={"#1B87C3"}
                            id="form_description"
                          >
                            {res.form_description
                              ? `(${res.form_description})`
                              : "(No description)"}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            m: 2,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                m: 1,
                              }}
                            >
                              <Typography
                                sx={{ width: "9rem" }}
                                fontWeight={"bold"}
                                color={"#1B87C3"}
                                id="appraisee_name"
                              >
                                Appraisee :
                              </Typography>
                              <Typography>{res.employee_name} </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                m: 1,
                              }}
                            >
                              <Typography
                                sx={{ width: "9rem" }}
                                fontWeight={"bold"}
                                color={"#1B87C3"}
                                id="manager_name"
                              >
                                Manager Name :{" "}
                              </Typography>
                              <Typography>{res.manager_name}</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                m: 1,
                              }}
                            >
                              <Typography
                                sx={{ width: "9rem" }}
                                fontWeight={"bold"}
                                color={"#1B87C3"}
                                id="employee_code"
                              >
                                Employee Code :{" "}
                              </Typography>
                              <Typography>{res.employee_code} </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                m: 1,
                              }}
                            >
                              <Typography
                                sx={{ width: "9rem" }}
                                fontWeight={"bold"}
                                color={"#1B87C3"}
                                id="designation"
                              >
                                Designation :{" "}
                              </Typography>
                              <Typography>
                                {res.employee_designation}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Divider />
                        <Box sx={{}}>
                          {res.response?.[0]?.categories.map(
                            (category, categoryIndex) => {
                              return (
                                <Box sx={{ m: 2, width: "75vw" }}>
                                  <Accordion
                                    sx={{
                                      m: 2,
                                      p: 0,
                                      width: "100%",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <AccordionSummary>
                                      <Box>
                                        <Typography
                                          variant="h5"
                                          fontWeight={"bold"}
                                          color={"#1B87C3"}
                                          id={`category_${categoryIndex + 1}`}
                                        >
                                          {String.fromCharCode(
                                            categoryIndex + "A".charCodeAt(0)
                                          ) +
                                            ". " +
                                            category.category}
                                        </Typography>
                                      </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Box>
                                        <Box>
                                          <Box sx={{ m: 2, p: 2 }}>
                                            {category.questions.map(
                                              (
                                                question: any,
                                                questionIndex
                                              ) => {
                                                return (
                                                  <Box
                                                    key={question.question_id}
                                                  >
                                                    <Box>
                                                      <Typography
                                                        fontWeight={"bold"}
                                                        fontSize={19}
                                                        variant="h6"
                                                        id={`category_${
                                                          categoryIndex + 1
                                                        }_question_${
                                                          questionIndex + 1
                                                        }`}
                                                      >
                                                        {questionIndex +
                                                          1 +
                                                          "."}{" "}
                                                        {question.question_name
                                                          ? question.question_name
                                                          : question.question}
                                                      </Typography>
                                                    </Box>
                                                    <Box sx={{ ml: 2, mr: 2 }}>
                                                      {question.answer_type ===
                                                      "short_answer" ? (
                                                        <Box>
                                                          <TextField
                                                            id={`employee_response_category__${
                                                              categoryIndex + 1
                                                            }_question_${
                                                              questionIndex + 1
                                                            }`}
                                                            required
                                                            name={`descriptive_${questionIndex}`}
                                                            size="small"
                                                            fullWidth
                                                            color="success"
                                                            multiline
                                                            sx={{
                                                              m: 2,
                                                              overflow: "auto",
                                                            }}
                                                            // disabled
                                                            maxRows={4}
                                                            value={
                                                              question[
                                                                responseKey as keyof Question
                                                              ]
                                                            }
                                                            style={{
                                                              height: "auto",
                                                            }}
                                                            InputProps={{
                                                              style: {
                                                                minHeight:
                                                                  "unset",
                                                              },
                                                              readOnly: true,
                                                            }}
                                                          />
                                                        </Box>
                                                      ) : (
                                                        <Box>
                                                          <Box
                                                            sx={{
                                                              display: "block",
                                                              alignItems:
                                                                "center",
                                                              m: 1,
                                                            }}
                                                          >
                                                            <RadioGroup
                                                              id={`employee_response_category__${
                                                                categoryIndex +
                                                                1
                                                              }_question_${
                                                                questionIndex +
                                                                1
                                                              }`}
                                                              name={`choices_${questionIndex}`}
                                                              value={
                                                                question[
                                                                  responseKey as keyof Question
                                                                ]
                                                              }
                                                            >
                                                              {question.answer_choices &&
                                                                question.answer_choices.map(
                                                                  (
                                                                    ans: any,
                                                                    answerValueIndex: number
                                                                  ) => (
                                                                    <FormControlLabel
                                                                      id={`employee_response_category__${
                                                                        categoryIndex +
                                                                        1
                                                                      }_question_${
                                                                        questionIndex +
                                                                        1
                                                                      }_answer_value_${
                                                                        answerValueIndex +
                                                                        1
                                                                      }`}
                                                                      required
                                                                      key={
                                                                        ans.option_id
                                                                      }
                                                                      value={
                                                                        ans.answer_value
                                                                      }
                                                                      control={
                                                                        <Radio
                                                                          id={`employee_response_category__${
                                                                            categoryIndex +
                                                                            1
                                                                          }_question_${
                                                                            questionIndex +
                                                                            1
                                                                          }_answer_value_${
                                                                            answerValueIndex +
                                                                            1
                                                                          }_radio_button`}
                                                                          color="success"
                                                                        />
                                                                      }
                                                                      label={
                                                                        ans.answer_value
                                                                      }
                                                                    />
                                                                  )
                                                                )}
                                                            </RadioGroup>
                                                          </Box>
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              m: 1,
                                                              p: 1,
                                                            }}
                                                          >
                                                            <Typography
                                                              fontWeight={
                                                                "bold"
                                                              }
                                                              paragraph
                                                              sx={{
                                                                width: "11rem",
                                                              }}
                                                            >
                                                              Comments :{" "}
                                                            </Typography>
                                                            <TextField
                                                              id={`employee_comment_category__${
                                                                categoryIndex +
                                                                1
                                                              }_question_${
                                                                questionIndex +
                                                                1
                                                              }`}
                                                              name="employee_comment"
                                                              color="success"
                                                              fullWidth
                                                              multiline
                                                              // rows={2}
                                                              // InputProps={{
                                                              //   readOnly: !edit,
                                                              // }}
                                                              maxRows={4}
                                                              style={{
                                                                height: "auto",
                                                                overflow:
                                                                  "auto",
                                                              }}
                                                              value={
                                                                question[
                                                                  commentKey as keyof Question
                                                                ]
                                                              }
                                                              inputProps={{
                                                                readonly:
                                                                  "true",
                                                              }}
                                                            />
                                                          </Box>
                                                        </Box>
                                                      )}
                                                    </Box>
                                                  </Box>
                                                );
                                              }
                                            )}
                                          </Box>
                                        </Box>
                                      </Box>
                                    </AccordionDetails>
                                  </Accordion>
                                </Box>
                              );
                            }
                          )}
                        </Box>
                      </FormControl>
                    </Box>
                  );
                } else {
                  return (
                    <>
                      <Box sx={{ mt: 10 }}>
                        <NothingToDisplayComponent />
                      </Box>
                    </>
                  );
                }
              })}
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default ShowThreeSixtyFormReview;
