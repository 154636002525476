import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Switch,
  SwitchProps,
  TextField,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import { setId } from "../../store/FormSlice";
import { viewHierarchicalForm } from "../../Service/Apis";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { setIsLoading } from "../../store/LoadingSlice";
import Skeletons from "../../components/Skeleton/Skeletons";
import { useCustomToast } from "../../components/Methods/SnackBar";
import { AppraisalViewFormType } from "./Types/AppraisalViewForm";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AppraisalViewForm = () => {
  const [viewForm, setViewForm] = useState<AppraisalViewFormType[]>([]); //state for saving the form
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [open, setOpen] = useState(false);
  const isLoading = useSelector((state: any) => state.loading.isLoading);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const axiosInstance = useAxiosInterceptor();
  const Navigate = useNavigate();

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");

  const handleOpenDelete2 = () => {
    setOpen(true);
  };
  const handleCLoseDelete2 = () => {
    setOpen(false);
  };

  //fetching the form
  useEffect(() => {
    const fetchAppraisalForm = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${viewHierarchicalForm}${formId}`)
        );
        setViewForm(response as AppraisalViewFormType[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchAppraisalForm();
  }, []);

  //  to display skeleton if the page is loading
  if (isLoading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10, display: "flex", justifyContent: "center" }}>
        {viewForm.map((res) => {
          return (
            <Box key={res.form_id} sx={{ maxWidth: "80vw" }}>
              <Box>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={res.manager_fields_visible}
                      readOnly
                    />
                  }
                  label="Manager Response Visibility"
                />
              </Box>
              <Box
                sx={{
                  mt: 1,
                  pt: 2,
                  display: "flex",
                  justifyContent: "center",
                  width: "100% ",
                }}
              >
                <Typography
                  color={"#1B87C3"}
                  fontWeight={"bold"}
                  letterSpacing={3}
                  variant="h4"
                  sx={{ textDecoration: "underline" }}
                >
                  {res.form_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  m: 1,
                  mt: 2,
                  mb: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" color={"#1B87C3"}>
                  {res.form_description
                    ? `(${res.form_description})`
                    : "(No description)"}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ m: 3, p: 2 }}>
                {res.sections.map((section, sectionIndex) => {
                  return (
                    <Box sx={{ m: 2, width: "75vw" }}>
                      <Accordion sx={{ m: 2, p: 0, width: "100%" }}>
                        <AccordionSummary>
                          <Box>
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              color={"#1B87C3"}
                            >
                              {String.fromCharCode(
                                sectionIndex + "A".charCodeAt(0)
                              ) +
                                ") " +
                                section.section}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ m: 2, p: 2 }}>
                            {section.categories.map(
                              (category, categoryIndex) => {
                                return (
                                  <Box>
                                    <Box>
                                      <Typography
                                        variant="h5"
                                        fontWeight={"bold"}
                                        color={"#1B87C3"}
                                      >
                                        {String.fromCharCode(
                                          categoryIndex + "a".charCodeAt(0)
                                        ) +
                                          ". " +
                                          category.category}
                                      </Typography>
                                    </Box>
                                    <Box sx={{ m: 2, p: 2 }}>
                                      {category.questions.map(
                                        (question, questionIndex) => {
                                          return (
                                            <Box key={question.question_id}>
                                              <Box>
                                                <Typography
                                                  fontWeight={"bold"}
                                                  fontSize={19}
                                                  variant="h6"
                                                >
                                                  {questionIndex + 1 + "."}{" "}
                                                  {question.question}
                                                </Typography>
                                              </Box>
                                              <Box sx={{ ml: 2, mr: 2 }}>
                                                {question.answer_type ===
                                                "short_answer" ? (
                                                  <Box>
                                                    <TextField
                                                      required
                                                      name={`descriptive_${questionIndex}`}
                                                      size="small"
                                                      fullWidth
                                                      //   variant="standard"
                                                      color="success"
                                                      multiline
                                                      rows={1}
                                                      sx={{ m: 2 }}
                                                      disabled

                                                      // onChange={(e) => {
                                                      //   handleChange(
                                                      //     e,
                                                      //     data.question_id,
                                                      //     res.form_id
                                                      //   );
                                                      // }}
                                                    />
                                                  </Box>
                                                ) : (
                                                  <Box>
                                                    <Box
                                                      sx={{
                                                        display: "block",
                                                        alignItems: "center",
                                                        m: 1,
                                                      }}
                                                    >
                                                      <RadioGroup
                                                        name={`choices_${questionIndex}`}
                                                      >
                                                        {question.answer_choices &&
                                                          question.answer_choices.map(
                                                            (ans: any) => (
                                                              <FormControlLabel
                                                                // required
                                                                disabled
                                                                key={
                                                                  ans.answer_id
                                                                }
                                                                value={
                                                                  ans.answer_value
                                                                }
                                                                control={
                                                                  <Radio color="success" />
                                                                }
                                                                label={
                                                                  ans.answer_value
                                                                }
                                                              />
                                                            )
                                                          )}
                                                        {/* <Divider
                                                          sx={{
                                                            // color: "green",
                                                            height: "1px",
                                                            // backgroundColor: "green",
                                                          }}
                                                        /> */}
                                                      </RadioGroup>
                                                    </Box>
                                                  </Box>
                                                )}
                                              </Box>
                                            </Box>
                                          );
                                        }
                                      )}
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="submit"
                  color="success"
                  size="large"
                  variant="contained"
                  onClick={() => {
                    Navigate("/createdforms");
                  }}
                >
                  Back
                </Button>

                {viewForm[0].is_uploaded ? (
                  <Button
                    color="success"
                    size="large"
                    variant="contained"
                    onClick={() => {
                      handleOpenDelete2();
                    }}
                  >
                    Uploaded Teams
                  </Button>
                ) : (
                  <Button
                    color="success"
                    size="large"
                    variant="contained"
                    onClick={() => {
                      Navigate("/editform");
                      dispatch(setId(res.form_id));
                      localStorage.setItem("formid", res.form_id);
                    }}
                  >
                    Edit
                  </Button>
                )}
              </Box>
              <Box>
                <Modal
                  open={open}
                  onClose={handleCLoseDelete2}
                  sx={{
                    m: 1,
                    // width: 400,
                  }}
                >
                  <Card
                    elevation={11}
                    sx={{
                      ml: "5rem",
                      mr: "5rem",
                      m: 2,
                      p: 2,
                      position: "absolute" as "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",

                      borderRadius: "5px",
                      boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <IconButton onClick={() => handleCLoseDelete2()}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          m: 1,
                        }}
                      >
                        <Typography color={"#1B87C3"} variant="h4">
                          Teams you have Uploaded form to
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Divider
                        sx={{
                          m: 3,
                          color: "green",
                          height: "1px",
                          backgroundColor: "green",
                        }}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ m: 4 }}>
                        {viewForm[0].teams.map((teams: any, index: number) => {
                          return (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="h5" fontWeight={"bold"}>
                                  {index + 1}.
                                </Typography>
                                <Typography variant="h5">
                                  {teams.project_name}
                                </Typography>
                              </Box>
                            </>
                          );
                        })}
                      </Box>
                      <Box
                        sx={{
                          m: 2,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Button
                          color="success"
                          onClick={() => handleCLoseDelete2()}
                        >
                          Close
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Modal>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default AppraisalViewForm;