import {
  Autocomplete,
  Box,
  Button,
  Fade,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { AdminDownload, showTeams, viewUsers } from "../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { setIsLoading } from "../../store/LoadingSlice";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialStateEmployee = {
  id: "",
  employee_code: "",
  first_name: "",
  last_name: "",
  gender: "",
  roles: [],
  designation: "",
  date_of_birth: "",
  date_of_joining: "",
  date_of_confirmation: "",
  date_of_exit: "",
  email: "",
  password: "",
};

const ReviewDownload = () => {
  const [value, setValue] = React.useState(0);
  const [getEmployee, setGetEmployee] = useState<
    (typeof initialStateEmployee)[]
  >([]);

  const [selectedEmployee, setSelectedEmployee] = useState<
    typeof initialStateEmployee | null
  >(null);
  const [employeeOptions, setEmployeeOptions] = useState<
    (typeof initialStateEmployee)[]
  >([]);
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const [teamOptions, setTeamOptions] = useState<any[]>([]);

  const [getTeamsData, setGetTeamsData] = useState([
    {
      team_id: "",
      manager: "",
      manager_name: "",
      project_name: "",
      team: [
        {
          team_id: "",
          team_member_id: "",
          employee_id: "",
          employee_name: "",
        },
      ],
    },
  ]);

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const axiosInstance = useAxiosInterceptor();

  const Navigate = useNavigate();

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //for excel download
  const handleBulkDownload = async (e: any) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(AdminDownload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer", // Set the response type to 'arraybuffer' to receive binary data
      });

      const data = new Uint8Array(response.data);
      const workbook = XLSX.read(data, { type: "array" });

      // Customize the workbook or perform any necessary operations

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "downloadedFile.xlsx");
      handleShowToast("Downloading...", "default", true);
    } catch (error) {
      console.log(error);
      handleShowToast("Error downloading", "default", true);
    }
  };

  // Download excel specific to employee.
  const handleEmployeeDownload = async (e: any) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(
        `${AdminDownload}?employee_id=${selectedEmployee?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer", // Set the response type to 'arraybuffer' to receive binary data
        }
      );

      const data = new Uint8Array(response.data);
      const workbook = XLSX.read(data, { type: "array" });

      // Customize the workbook or perform any necessary operations

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "downloadedFile.xlsx");
      handleShowToast("Downloading...", "default", true);
    } catch (error) {
      console.log(error);
      handleShowToast("Error downloading", "default", true);
    }
  };

  // Download Excel specific to teams.
  const handleTeamDownload = async (e: any) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(
        `${AdminDownload}?team_id=${selectedTeam?.team_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer", // Set the response type to 'arraybuffer' to receive binary data
        }
      );

      const data = new Uint8Array(response.data);
      const workbook = XLSX.read(data, { type: "array" });

      // Customize the workbook or perform any necessary operations

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "downloadedFile.xlsx");
      handleShowToast("Downloading...", "default", true);
    } catch (error) {
      console.log(error);
      handleShowToast("Error downloading", "default", true);
    }
  };

  // API call to get employee details.
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(viewUsers));
        setGetEmployee(response as any);
        setEmployeeOptions(response as any); // Set the employee options here
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchUsersData();
  }, []);

  // API call to get team details.
  useEffect(() => {
    const fetchTeamsData = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(showTeams));
        setGetTeamsData(response as any);
        setTeamOptions(response as any);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchTeamsData();
  }, []);
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", width: "98vw" }}>
        <Box>
          <Box
            sx={{
              mt: 12,
              mb: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="inherit"
              // indicatorColor="secondary"
              sx={{
                "& .MuiTabs-root": {
                  color: "#1B87C3",
                  extDecorationColor: "#1B87C3",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#1B87C3", // This sets the indicator color to green
                  textDecorationColor: "#1B87C3",
                },
              }}
            >
              <Tab
                label="Download All Reviews"
                {...a11yProps(0)}
                // sx={{ color: "green" }}
              />
              <Tab
                label="Download Employee Review"
                {...a11yProps(1)}
                // sx={{ color: "green" }}
              />
              <Tab
                label="Download Team Review"
                {...a11yProps(2)}
                // sx={{ color: "green" }}
              />
            </Tabs>
          </Box>
          <Box>
            <CustomTabPanel value={value} index={0}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="info"
                  type="submit"
                  variant="contained"
                  onClick={handleBulkDownload}
                  sx={{ m: 1 }}
                >
                  Download All Reviews
                </Button>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Autocomplete
                  id="employee-autocomplete"
                  options={employeeOptions}
                  color="info"
                  getOptionLabel={(option: any) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  value={selectedEmployee}
                  onChange={(event: any, newValue: any) =>
                    setSelectedEmployee(newValue)
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Select Employee"
                      variant="outlined"
                      sx={{ width: "35vw" }}
                      color="info"
                    />
                  )}
                />
                {selectedEmployee && (
                  <Fade
                    in={true}
                    timeout={{ appear: 1000, enter: 1000, exit: 2000 }}
                  >
                    <Button
                      color="info"
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={handleEmployeeDownload}
                    >
                      Download
                    </Button>
                  </Fade>
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Autocomplete
                  id="team-autocomplete"
                  options={teamOptions}
                  color="info"
                  getOptionLabel={(option: any) => option.project_name}
                  value={selectedTeam}
                  onChange={(event: any, newValue: any) =>
                    setSelectedTeam(newValue)
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Select Team"
                      variant="outlined"
                      sx={{ width: "35vw" }}
                      color="info"
                    />
                  )}
                />
                {selectedTeam && (
                  <Fade
                    in={true}
                    timeout={{ appear: 1000, enter: 1000, exit: 2000 }}
                  >
                    <Button
                      color="info"
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={handleTeamDownload}
                    >
                      Download
                    </Button>
                  </Fade>
                )}
              </Box>
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default ReviewDownload;
