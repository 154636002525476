import React, { useEffect, useMemo, useState } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box, TextField, Button, Paper } from "@mui/material";
import axios from "axios";
import { ThemeProvider, createTheme, makeStyles } from "@mui/material/styles";

import useAxiosInterceptor from "../layout/AxiosInstance";
import { Employee_and_manager } from "../../Service/Apis";
import CustomNoRowsOverlay from "../Data/NoRowsOverlayComponent";

// Define the response type
type EmployeeData = {
  employee_id: string;
  employee_name: string;
  managers: {
    reporting_manager_id: string;
    reporting_manager_name: string;
  }[];
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#006600",
      contrastText: "#fff", //button text white instead of black
    },
    background: {
      default: "#394764",
    },
  },
  typography: {
    fontFamily: ["Signika Negative"].join(","),
    fontSize: 15,
  },
});

const EmployeeAndManager = () => {
  const [employeeData, setEmployeeData] = useState<EmployeeData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const axiosInstance = useAxiosInterceptor();

  const columns: GridColDef[] = [
    // { field: "employee_id", headerName: "Employee ID", flex: 1 },
    {
      field: "employee_name",
      headerName: "Employee Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Employee Name
        </strong>
      ),
    },
    {
      field: "managers",
      headerName: "Managers",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Managers
        </strong>
      ),

      valueGetter: (params) => {
        const managers = params.value as {
          reporting_manager_id: string;
          reporting_manager_name: string;
        }[];
        return managers
          .map((manager) => manager.reporting_manager_name)
          .join(", ");
      },
    },
  ];

  const filteredData = useMemo(() => {
    if (searchTerm === "") {
      return employeeData;
    }
    return employeeData.filter((employee) =>
      employee.employee_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [employeeData, searchTerm]);

  const rows = useMemo(() => {
    return filteredData.map((employee, index) => ({
      ...employee,
      id: index.toString(),
    }));
  }, [filteredData]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  // Fetch employee data
  useEffect(() => {
    const token = localStorage.getItem("token");
    axiosInstance
      .get<EmployeeData[]>(`${Employee_and_manager}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        setEmployeeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Box>
      {/* <Box
        component="form"
        onSubmit={(e) => e.preventDefault()}
        sx={{ m: 2, display: "flex", justifyContent: "right", p: 1 }}
      >
        <TextField
          color="info"
          label="Search Employee"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          size="small"
          sx={{ mr: 2, width: "40%" }}
          inputProps={{
            readOnly: false,
          }}
        />
        <Button
          type="submit"
          size="small"
          color="info"
          variant="text"
          sx={{ m: 0 }}
        >
          Search
        </Button>
      </Box> */}

      <Paper sx={{ m: 2 }} style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          disableColumnMenu
          sx={{
            width: "100%",
            height: "100%",
            "--DataGrid-overlayHeight": "50vh",
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pagination
          pageSizeOptions={[5, 10, 15]}
          rows={rows}
          columns={columns}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            noResultsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              style: { margin: "0.5rem" },
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default EmployeeAndManager;
