import * as React from "react";
import { useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Badge, CardMedia } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import axios from "axios";
import useAxiosInterceptor from "../layout/AxiosInstance";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { enqueueSnackbar } from "notistack";
import LogIn from "../../pages/Login/LogIn";
import {
  ViewUser,
  logout,
  viewNotificationNumber,
  viewNotifications,
} from "../../Service/Apis";
import { clearToken, setIsLoggedIn } from "../../store/AuthSlice";
import { getItems, getSidebarItems } from "../Data/data";
import { setRole } from "../../store/FormSlice";
// import yoda from "../../asset/home-logo1.png";
import earthfitMini from "../../asset/earthfit-mini.png";
import { setFields } from "../../store/Personslice";
import { ToastVariant, useCustomToast } from "../Methods/SnackBar";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../store/actions";
import { RootState } from "../../store/Store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { error } from "console";
import { number } from "yargs";

const settings = ["Profile"];
type notification = {
  id: number;
  message: string;
  viewed: boolean;
  manager: string;
  response_id: string;
};

function Navbar() {
  const storedRole = localStorage.getItem("role");
  const roleArray = storedRole ? JSON.parse(storedRole) : [];

  const newRole =
    useSelector((state: any) => state.form.role) ||
    localStorage.getItem("nRole");

  const nrole = localStorage.getItem("nRole");
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const [anchorEl4, setAnchorEl4] = React.useState<null | HTMLElement>(null);

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedRole, setSelectedRole] = useState<string>(nrole || newRole);
  // const [rolesArray, setRolesArray] = useState<string[]>([]);
  const [displayFunc, setDisplayFunc] = useState<any[]>(
    getItems(nrole || newRole)
  ); //display role
  const [notification, setNotification] = useState<notification[]>([]); //notification display
  const [numberOfNoti, setNumberOfNoti] = useState(0); //numbber of notification

  const superAdmin = getSidebarItems(roleArray);

  const navigate = useNavigate();
  const showToast = useCustomToast();
  const dispatch = useDispatch();
  const picture = localStorage.getItem("profileImage");

  const axiosInstance = useAxiosInterceptor();

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const name: string = localStorage.getItem("email") || "default";

  // const superAdmin = sidebarItems[role||'employee'] ||[];

  const handleOptionClick = (option: any) => {
    setSelectedOption(option.label);
    setAnchorEl(null);
    navigate(`/${option.route}`);
  };

  const handlehowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleDispatch = () => {
    const refresh = localStorage.getItem("refresh");
    const token = localStorage.getItem("token");
    const data = {
      refresh: refresh,
    };
    const response = axiosInstance
      .post(`${logout}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("nRole");
        localStorage.removeItem("changeRole");
        dispatch(setRole("employee"));
        dispatch(clearToken());
        dispatch(setIsLoggedIn(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // localStorage.setItem("changeRole", selectedRole);
  const handleRoleChange = (role: string) => {
    setDisplayFunc(getItems(role));
    dispatch(setRole(role));
    const newRole = localStorage.getItem("nRole");
    // enqueueSnackbar(`Switched to ${role}`, {
    //   variant: "info",
    //   autoHideDuration: 1000,
    //   anchorOrigin: { vertical: "top", horizontal: "left" },
    //   style: {
    //     marginTop: "3rem",
    //   },
    // });
    handlehowToast(`Switched to ${role}`, "info", true);
    setAnchorEl2(null);
    setAnchorEl3(null);
    navigate("/dashboard");
  };

  const handleClickRoles = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClickRoles2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClickNotification = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl4(event.currentTarget);
    fetcNotificationCounthData();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //getting notifications
  const fetcNotificationCounthData = async () => {
    const token = localStorage.getItem("token");
    // if (newRole === "ADMIN" || newRole === "admin" || newRole === "Admin") {
    //   return;
    // }
    const response = axiosInstance
      .get(
        `${viewNotifications}${newRole.toLowerCase()}-role/check-notifications/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setNotification(res.data);
        setNumberOfNoti(0);
      })
      .catch((error) => {
        console.log(error);
      });

    // try {
    //   const response = await dispatch2(
    //     fetchData(
    //       `${viewNotifications}${newRole.toLowerCase()}-role/check-notifications/`
    //     )
    //   )
    //     .then((response) => {
    //       setNotification(response as notification[]);
    //       setNumberOfNoti(0);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  };
  //getting notification count
  useEffect(() => {
    const fetchnumber = async () => {
      const token = localStorage.getItem("token");
      // if (newRole === "ADMIN" || newRole === "admin" || newRole === "Admin") {
      //   return;
      // }
      const response = axiosInstance
        .get(
          `${viewNotificationNumber}${newRole.toLowerCase()}-role/notification-count/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setNumberOfNoti(res.data.unseen_notifications);
        })
        .catch((error) => {
          console.log(error);
        });

      // try {
      //   const response = await dispatch2(
      //     fetchData(
      //       `${viewNotificationNumber}${newRole.toLowerCase()}-role/notification-count/`
      //     )
      //   )
      //     .then((response: any) => {
      //       setNumberOfNoti(response?.unseen_notifications);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // } catch (error) {
      //   console.log(error);
      // }
    };

    fetchnumber();
  }, []);
  // useEffect(() => {
  // 	const FetchData = async () => {
  // 		const emailid = localStorage.getItem("email");
  // 		const newtoken =
  // 			useSelector((state: any) => state.auth.token) ||
  // 			localStorage.getItem("token");
  // 		try {
  // 			const response = await axiosInstance.get(`${ViewUser}${emailid}`, {
  // 				headers: {
  // 					Authorization: `Bearer ${newtoken.access}`,
  // 				},
  // 			});
  // 			const person = response.data[0];
  // 			dispatch(setFields(person));
  // 			if (person && person.roles) {
  // 				localStorage.setItem("role", JSON.stringify(person.roles));
  // 				dispatch(setRole(person.role[0]));
  // 			}
  // 		} catch (error: any) {}
  // 	};
  // 	FetchData();
  // }, []);

  return (
    <header>
      <AppBar position="fixed" sx={{ backgroundColor: "white" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              // href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                maxWidth: "100%",
              }}
            >
              <Link to="/dashboard">
                <CardMedia
                  id="navbarLogo"
                  component="img"
                  src={earthfitMini}
                  sx={{
                    width: "12rem",
                    ml: 4,
                    transition: "transform 0.1s ease",
                    "&:hover": {
                      transform: "scale(0.9)",
                      // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                ></CardMedia>
              </Link>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                id="responsiveMenu"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {displayFunc &&
                  Array.isArray(displayFunc) &&
                  displayFunc.map((page, index) => (
                    <MenuItem
                      key={index}
                      id={page.label.replace(/\s/g, "").toLowerCase()}
                      //   className="menuItems"
                      onClick={() => handleOptionClick(page)}
                      sx={{
                        color: "#1B87C3",
                        "&:hover": { color: "#1B87C3" },
                      }}
                    >
                      {page.label}
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                justifyContent: "center",
              }}
            >
              <Link to="/dashboard">
                <CardMedia
                  component="img"
                  src={earthfitMini}
                  sx={{ width: "10rem" }}
                ></CardMedia>
              </Link>
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "right",
                mr: 2,
              }}
            >
              {displayFunc.length <= 1 ? (
                <>
                  <Button
                    onClick={() => handleOptionClick(displayFunc[0])}
                    sx={{
                      color: "#1B87C3",
                      "&:hover": { color: "#1B87C3" },
                    }}
                  >
                    {displayFunc[0].label}
                  </Button>
                </>
              ) : (
                <Box>
                  <Tooltip title="Click to see pages">
                    <Button
                      id="dashboardMenu"
                      onClick={handleClick}
                      color="info"
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      {selectedOption !== null && selectedOption !== ""
                        ? selectedOption
                        : displayFunc[0]?.label || ""}
                    </Button>
                  </Tooltip>
                  <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    {displayFunc &&
                      Array.isArray(displayFunc) &&
                      displayFunc.map((option, index) => (
                        <MenuItem
                          key={index}
                          id={option.label.replace(/\s/g, "")}
                          onClick={() => handleOptionClick(option)}
                          sx={{
                            color: "#1B87C3",
                            "&:hover": { color: "#1B87C3" },
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                  </Menu>
                </Box>
              )}

              <Box sx={{}}>
                <Tooltip title="Click to Switch Your Role">
                  <Button
                    id="roleSwitch"
                    onClick={handleClickRoles}
                    color="info"
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    {selectedRole}
                    {/* {(selectedRole !== null && selectedRole) || roleArray[0]} */}
                  </Button>
                </Tooltip>
                <Menu
                  id="roleMenu"
                  anchorEl={anchorEl2}
                  open={Boolean(anchorEl2)}
                  defaultValue={roleArray[0]}
                  onClose={() => setAnchorEl2(null)}
                >
                  {roleArray.map((role: string, index: number) => {
                    return (
                      <MenuItem
                        key={index}
                        id={role}
                        onClick={() => {
                          handleRoleChange(role);
                        }}
                        sx={{
                          color: "#1B87C3",
                          "&:hover": { color: "#1B87C3" },
                        }}
                      >
                        {role}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Box>
            </Box>

            <Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
              <Tooltip title="Click to see pages">
                <Button
                  id="responsiveRoleSwitch"
                  onClick={handleClickRoles2}
                  // color="info"
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {selectedRole}
                  {/* {(selectedRole !== null && selectedRole) || roleArray[0]} */}
                </Button>
              </Tooltip>
              <Menu
                anchorEl={anchorEl3}
                id="responsiveRoleMenu"
                open={Boolean(anchorEl3)}
                onClose={() => setAnchorEl3(null)}
              >
                {roleArray.map((role: string, index: number) => {
                  return (
                    <MenuItem
                      key={index}
                      id={role}
                      onClick={() => {
                        handleRoleChange(role);
                      }}
                      sx={{
                        color: "#1B87C3",
                        "&:hover": { color: "#1B87C3" },
                      }}
                    >
                      {role}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>

            <Box sx={{ mr: 2 }}>
              <Tooltip title="Click to see Notifications">
                <IconButton
                  onClick={handleClickNotification}
                  id="notification"
                  //   color="info"
                  //   endIcon={<KeyboardArrowDownIcon />}
                >
                  <Badge
                    id="notificationCount"
                    badgeContent={numberOfNoti !== 0 ? numberOfNoti : null}
                    overlap="circular"
                    color="info"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={
                      {
                        // "& .MuiBadge-badge": {
                        // 	top: "10%",
                        // 	right: "-4px",
                        // 	transform: "translate(50%, -50%)",
                        // 	padding: "0 4px",
                        // 	// color: "black",
                        // 	// backgroundColor: "white",
                        // },
                      }
                    }
                  >
                    <NotificationsNoneOutlinedIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl4}
                open={Boolean(anchorEl4)}
                onClose={() => setAnchorEl4(null)}
                sx={{
                  "& .MuiMenu-paper": {
                    minWidth: 280,
                    minHeight: 280,
                    maxWidth: 280,
                    maxHeight: 280,
                  },
                  overflow: "auto",
                }}
              >
                {Array.isArray(notification) && notification.length === 0 ? (
                  <MenuItem
                    sx={{
                      color: "#1B87C3",
                      "&:hover": { color: "#1B87C3" },
                      justifyContent: "center",
                      alignItems: "center",
                      display: "center",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Tooltip
                      title={"No new Notification"}
                      placement="top-start"
                    >
                      <Box style={{ whiteSpace: "pre-line" }}>
                        No new notification
                      </Box>
                    </Tooltip>
                  </MenuItem>
                ) : (
                  Array.isArray(notification) &&
                  notification.map((notifi, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "#1B87C3",
                        "&:hover": { color: "#1B87C3" },
                      }}
                    >
                      <Tooltip title={notifi.message} placement="top-start">
                        <Box style={{ whiteSpace: "pre-line" }}>
                          {notifi.message}
                        </Box>
                      </Tooltip>
                    </MenuItem>
                  ))
                )}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Options">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    className="ProfileIcon"
                    sx={{
                      color: "white",
                      background: "#1B87C3",
                      //   "&:hover": { color: "#1B87C3" },
                    }}
                    alt={name.toUpperCase()}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, index) => (
                  <MenuItem
                    id={setting}
                    key={index}
                    onClick={handleCloseUserMenu}
                  >
                    <Link
                      to={`/${setting.replace(/\s/g, "").toLowerCase()}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#1B87C3",
                          "&:hover": { color: "#1B87C3" },
                        }}
                      >
                        {setting}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
                <MenuItem id="logout">
                  <Link
                    to={"/logout"}
                    style={{ textDecoration: "none" }}
                    onClick={handleDispatch}
                  >
                    <Typography
                      textAlign={"center"}
                      sx={{
                        color: "#1B87C3",
                        "&:hover": { color: "#1B87C3" },
                      }}
                    >
                      Logout
                    </Typography>
                  </Link>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </header>
  );
}
export default Navbar;
