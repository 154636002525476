import { useEffect, useState } from "react";

import { Box } from "@mui/material";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { rankEmployees } from "../../Service/Apis";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import EmployeeTable from "../../components/Employee/EmployeeTableRanking";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import { RootState } from "../../store/Store";
import {
  DataPayload,
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../store/actions";
import { Project } from "./Types/RankingTypes";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

const Ranking = () => {
  const [data, setData] = useState<Project[]>([]);

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchmyData();
  }, []);

  // Getting the table data and the ranks
  const fetchmyData = async () => {
    try {
      const response: DataPayload = await dispatch2(
        fetchData(`${rankEmployees}`)
      );
      setData(response as Project[]);
    } catch (error) {}
  };

  //displaying the no Data component if there is no data present
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", width: "98vw" }}>
        <Box sx={{ mt: 12, mb: 10 }}>
          {/* <Card
					elevation={11}
					sx={{
						m: 2,
						p: 2,
						display: "flex",
						justifyContent: "center",
						borderRadius: "5px",
						boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
					}}
				> */}
          {data.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                mt: 10,
              }}
            >
              <NothingToDisplayComponent />
            </Box>
          ) : (
            <EmployeeTable data={data} />
          )}
          {/* </Card> */}
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default Ranking;
