import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

import {
  editEmployeeSelfResponse,
  viewEmployeeSelfResponse,
} from "../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { labels } from "../Dashboard/ReviewForm";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  patchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { setIsLoading } from "../../store/LoadingSlice";
import Skeletons from "../../components/Skeleton/Skeletons";
import { postDataSuccess } from "../../store/AxiosSlice";

interface ResponseType {
  employee_code: string;
  designation: string;
  response_id: string;
  form_team_id: string;
  form_id: string;
  form_name: string;
  form_description: string;
  team_id: string;
  project_name: string;
  manager: string;
  employee_id: string;
  roles: string[];
  employee_name: string;
  employee_deadline: string;
  manager_deadline: string;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  response: Section[]; // Update this to use Section[] instead of [{...}]
}

interface Response1 {
  section_id: string;
  category_id: string;
  question_id: string;
  employee_response: string;
  employee_comment: string;
  employee_rating: string;
}

interface Section {
  section_id: string;
  section: string;
  categories: Category[];
}

interface Category {
  category_id: string;
  category: string;
  questions: Question[];
}

interface Question {
  question_id: string;
  question_name: string;
  employee_rating: string;
  employee_response: string;
  employee_comment: string;
  answer_type: string;
  answer_choices: {
    option_id: string;
    answer_value: string;
  }[];
}

interface SpecialCharErrors {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface MinCharacterError {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface SpacesErrors {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface HoverEffect {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_rating: number;
      };
    };
  };
}
const initialSpecialCharErrors: SpecialCharErrors = {};
const initialMinCharacterErrors: MinCharacterError = {};
const initialSpacesErrors: SpacesErrors = {};
const initialHoverEffect: HoverEffect = {};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ShowResponseAppraisalForm = () => {
  const [viewResponse, setViewResponse] = useState<ResponseType[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [specialCharErrors, setSpecialCharErrors] = useState<SpecialCharErrors>(
    initialSpecialCharErrors
  );
  const [hoverEffect, setHoverEffect] =
    useState<HoverEffect>(initialHoverEffect);

  const [spacesErrors, setSpacesErrors] =
    useState<SpacesErrors>(initialSpacesErrors);
  const [minCharacterError, setMinCharacterError] = useState<MinCharacterError>(
    initialMinCharacterErrors
  );
  const [flag, setFlag] = useState<boolean>(false);
  const [empRating, setEmpRating] = useState<boolean[][][]>([]);
  const [isSpecialCharError, setIsSpecialCharError] = useState<boolean>(false);
  const [isSpacesError, setIsSpacesError] = useState<boolean>(false);
  const [isMinCharacterError, setIsMinCharacterError] =
    useState<boolean>(false);
  const [value, setValue] = useState<number | null>(0);
  const axiosInstance = useAxiosInterceptor();

  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();

  const formId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const isLoading = useSelector((state: any) => state.loading.isLoading);

  // const success = useSelector((state: any) => state.http.data);

  const formTeamId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const token = localStorage.getItem("token");

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const hasSpecialCharacters = (input: string): boolean => {
    const specialCharacterPattern = /[!#%^*()_+\=[\]{};:"\\|<>/?]/;
    return specialCharacterPattern.test(input);
  };

  const handleChange = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    section_id: string,
    category_id: string,
    question_id: string,
    field: keyof Question,
    value: string,
    answer_type: string
  ): void => {
    const containsOnlySpaces = /^\s*$/.test(value);

    setViewResponse((prevResponse) => {
      // Create a new copy of the response array to update it
      const updatedResponse: ResponseType[] = prevResponse.map(
        (item, itemIndex) => {
          if (itemIndex === 0) {
            // Update only the first element (index 0) of the array
            const updatedSections: Section[] = item.response.map(
              (section, sIndex) => {
                if (sIndex === sectionIndex) {
                  // Update the specific section
                  const updatedCategories: Category[] = section.categories.map(
                    (category, cIndex) => {
                      if (cIndex === categoryIndex) {
                        // Update the specific category
                        const updatedQuestions: Question[] =
                          category.questions.map((question, qIndex) => {
                            if (qIndex === questionIndex) {
                              // Update the specific question
                              return {
                                ...question,
                                [field]: value ? value : "",
                                question_id,
                              };
                            }
                            return question;
                          });

                        return {
                          ...category,
                          category_id,
                          questions: updatedQuestions,
                        };
                      }
                      return category;
                    }
                  );

                  return {
                    ...section,
                    section_id,
                    categories: updatedCategories,
                  };
                }
                return section;
              }
            );

            return {
              ...item,
              response: updatedSections,
            };
          }

          // For other elements (if any), keep them unchanged
          return item;
        }
      );

      return updatedResponse;
    });

    const updatedSpecialCharErrors = { ...specialCharErrors };
    const sectionErrors = updatedSpecialCharErrors[sectionIndex] || {};
    const categoryErrors = sectionErrors[categoryIndex] || {};
    const questionErrors = categoryErrors[questionIndex] || {
      employee_response: false,
      employee_comment: false,
    };

    const updatedSpacesErrors = { ...spacesErrors };
    const sectionSpacesErrors = updatedSpacesErrors[sectionIndex] || {};
    const categorySpacesErrors = sectionSpacesErrors[categoryIndex] || {};
    const questionSpacesErrors = categorySpacesErrors[questionIndex] || {
      employee_response: false,
    };

    const updatedMinCharacterError = { ...minCharacterError };
    const sectionMinCharatcerError =
      updatedMinCharacterError[sectionIndex] || {};
    const categoryMinCharacterErorr =
      sectionMinCharatcerError[categoryIndex] || {};
    const questionMinCharacterError = categoryMinCharacterErorr[
      questionIndex
    ] || {
      // employee_comment: false,
      employee_response: false,
    };

    // Check for special characters in employee_response and employee_comment
    if (field === "employee_response") {
      questionErrors.employee_response = hasSpecialCharacters(value);
      questionSpacesErrors.employee_response = containsOnlySpaces;
      if (answer_type === "short_answer") {
        questionMinCharacterError.employee_response = value.length < 3;
      }
    } else if (field === "employee_comment") {
      questionErrors.employee_comment = hasSpecialCharacters(value);
      // questionMinCharacterError.employee_comment = value.length < 3;
    }

    // Update the specialCharErrors state
    categoryErrors[questionIndex] = questionErrors;
    sectionErrors[categoryIndex] = categoryErrors;
    updatedSpecialCharErrors[sectionIndex] = sectionErrors;

    setSpecialCharErrors(updatedSpecialCharErrors);

    categorySpacesErrors[questionIndex] = questionSpacesErrors;
    sectionSpacesErrors[categoryIndex] = categorySpacesErrors;
    updatedSpacesErrors[sectionIndex] = sectionSpacesErrors;

    setSpacesErrors(updatedSpacesErrors);

    categoryMinCharacterErorr[questionIndex] = questionMinCharacterError;
    sectionMinCharatcerError[categoryIndex] = categoryMinCharacterErorr;
    updatedMinCharacterError[sectionIndex] = sectionMinCharatcerError;

    setMinCharacterError(updatedMinCharacterError);
    let isAnySpecialCharError = false;
    Object.values(updatedSpecialCharErrors).forEach((categories) => {
      Object.values(categories).forEach((questions) => {
        Object.values(questions).forEach((error) => {
          if (error.employee_response || error.employee_comment) {
            isAnySpecialCharError = true;
          }
        });
      });
    });

    let isAnySpacesError = false;
    Object.values(updatedSpacesErrors).forEach((categories) => {
      Object.values(categories).forEach((questions) => {
        Object.values(questions).forEach((error) => {
          if (error.employee_response) {
            isAnySpacesError = true;
          }
        });
      });
    });

    let isMinCharacterErrors = false;
    Object.values(updatedMinCharacterError).forEach((categories) => {
      Object.values(categories).forEach((questions) => {
        Object.values(questions).forEach((error) => {
          if (error.employee_response) {
            isMinCharacterErrors = true;
          }
        });
      });
    });
    setIsMinCharacterError(isMinCharacterErrors);
    setIsSpacesError(isAnySpacesError);
    setIsSpecialCharError(isAnySpecialCharError);
  };

  const initializeEmpRatingState = (response: ResponseType) => {
    const empRatingArray: boolean[][][] = [];

    response?.response?.forEach((section, sectionIndex) => {
      const sectionEmpRating: boolean[][] = [];

      section?.categories?.forEach((category, categoryIndex) => {
        const categoryEmpRating: boolean[] = [];

        category?.questions?.forEach((question) => {
          const hasEmployeeRating =
            question?.hasOwnProperty("employee_rating") &&
            question.employee_rating !== null &&
            question.employee_rating !== "";
          categoryEmpRating.push(hasEmployeeRating);
        });

        sectionEmpRating.push(categoryEmpRating);
      });

      empRatingArray.push(sectionEmpRating);
    });

    setEmpRating(empRatingArray);
  };

  // Edit response.
  const handleSubmit = (e: any) => {
    e.preventDefault();
    //console.log(viewResponse[0].response);

    const isEveryEmpRatingTrue = empRating.every((section) => {
      return section.every((category) => {
        return category.every((questionEmpRating) => {
          return questionEmpRating === true;
        });
      });
    });

    if (!isEveryEmpRatingTrue) {
      setFlag(true);
      handleShowToast("Please fill all details", "error", true);
      return;
    }

    const responseToArray = (response: ResponseType): Response1[] => {
      const responseArray: Response1[] = [];

      response.response.forEach((section) => {
        const { section_id } = section;

        section.categories.forEach((category) => {
          const { category_id } = category;

          category.questions.forEach((question) => {
            const {
              question_id,
              employee_response,
              employee_comment,
              employee_rating,
            } = question;

            const response1: Response1 = {
              section_id,
              category_id,
              question_id,
              employee_response: employee_response,
              employee_comment:
                employee_comment !== undefined ? employee_comment : "",
              employee_rating: employee_rating,
            };
            responseArray.push(response1);
          });
        });
      });

      return responseArray;
    };

    const data = responseToArray(viewResponse[0]);

    const response_id = viewResponse[0].response_id;

    try {
      const response = dispatch2(
        patchData(`${editEmployeeSelfResponse}${response_id}`, data)
      )
        .then((response) => {
          handleShowToast("Updated Successfully!", "success", true);
          dispatch(postDataSuccess(null));
          Navigate("/dashboard");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // API call to fetch soecific response.
  useEffect(() => {
    const fetchEmployeeSelfResponse = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${viewEmployeeSelfResponse}${formId}`)
        );

        setViewResponse(response as ResponseType[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchEmployeeSelfResponse();
  }, []);

  useEffect(() => {
    initializeEmpRatingState(viewResponse[0]);
  }, [viewResponse]);

  if (isLoading) {
    return <Skeletons />;
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box
        sx={{
          mt: 12,
          mb: 10,
          display: "flex",
          justifyContent: "center",
          width: "98vw",
        }}
      >
        <Box>
          {viewResponse?.map((res) => {
            return (
              <Box key={res.form_id} component="form" onSubmit={handleSubmit}>
                <FormControl>
                  <Box
                    sx={{
                      mt: 1,
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                      width: "100% ",
                    }}
                  >
                    <Typography
                      // color={"#1B87C3"}
                      fontWeight={"bold"}
                      letterSpacing={3}
                      variant="h4"
                      sx={{ textDecoration: "underline" }}
                    >
                      {res.form_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      m: 1,
                      mt: 2,
                      mb: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">
                      {res.form_description
                        ? `(${res.form_description})`
                        : "(No description)"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      m: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          // color={"#1B87C3"}
                        >
                          Appraisee :{" "}
                        </Typography>
                        <Typography>{res.employee_name}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          // color={"#1B87C3"}
                        >
                          Manager Name :{" "}
                        </Typography>
                        <Typography>{res.manager}</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          // color={"#1B87C3"}
                        >
                          Employee Code :{" "}
                        </Typography>
                        <Typography>{res.employee_code}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                        <Typography
                          sx={{ width: "9rem" }}
                          fontWeight={"bold"}
                          // color={"#1B87C3"}
                        >
                          Designation :{" "}
                        </Typography>
                        <Typography>{res.designation}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ display: "flex", justifyContent: "right", m: 2 }}>
                    <Button
                      type="submit"
                      color="info"
                      size="large"
                      // variant="contained"
                      disabled={edit}
                      onClick={() => {
                        setEdit(!edit);
                      }}
                    >
                      Edit
                    </Button>
                  </Box>
                  <Box sx={{}}>
                    {res.response.map((section, sectionIndex) => {
                      return (
                        <Box sx={{ m: 2, width: "75vw" }}>
                          <Accordion sx={{ m: 2, p: 0, width: "100%" }}>
                            <AccordionSummary>
                              <Box>
                                <Typography
                                  variant="h5"
                                  fontWeight={"bold"}
                                  // color={"#1B87C3"}
                                >
                                  {String.fromCharCode(
                                    sectionIndex + "A".charCodeAt(0)
                                  ) +
                                    ") " +
                                    section.section}
                                </Typography>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box sx={{ m: 2, p: 2 }}>
                                {section.categories.map(
                                  (category, categoryIndex) => {
                                    return (
                                      <Box>
                                        <Box>
                                          <Typography
                                            variant="h5"
                                            fontWeight={"bold"}
                                            // color={"#1B87C3"}
                                          >
                                            {String.fromCharCode(
                                              categoryIndex + "a".charCodeAt(0)
                                            ) +
                                              ". " +
                                              category.category}
                                          </Typography>
                                        </Box>
                                        <Box sx={{ m: 2, p: 2 }}>
                                          {category.questions.map(
                                            (question, questionIndex) => {
                                              return (
                                                <Box key={question.question_id}>
                                                  <Box>
                                                    <Typography
                                                      fontWeight={"bold"}
                                                      fontSize={19}
                                                      // variant="h6"
                                                    >
                                                      {questionIndex + 1 + "."}{" "}
                                                      {question.question_name}
                                                    </Typography>
                                                  </Box>
                                                  <Box sx={{ ml: 2, mr: 2 }}>
                                                    {question.answer_type ===
                                                    "short_answer" ? (
                                                      <Box>
                                                        <TextField
                                                          required
                                                          name={`descriptive_${questionIndex}`}
                                                          size="small"
                                                          fullWidth
                                                          color="info"
                                                          multiline
                                                          InputProps={{
                                                            readOnly: !edit,
                                                          }}
                                                          maxRows={4}
                                                          sx={{
                                                            m: 2,
                                                            overflow: "auto",
                                                          }}
                                                          // disabled
                                                          value={
                                                            question.employee_response
                                                          }
                                                          onChange={(e) => {
                                                            handleChange(
                                                              sectionIndex,
                                                              categoryIndex,
                                                              questionIndex,
                                                              section.section_id,
                                                              category.category_id,
                                                              question.question_id,
                                                              "employee_response",
                                                              e.target.value,
                                                              question.answer_type
                                                            );
                                                          }}
                                                          helperText={
                                                            <>
                                                              {specialCharErrors[
                                                                sectionIndex
                                                              ]?.[
                                                                categoryIndex
                                                              ]?.[questionIndex]
                                                                ?.employee_response && (
                                                                <Typography
                                                                  color={
                                                                    "error"
                                                                  }
                                                                >
                                                                  Special
                                                                  Characters not
                                                                  allowed.
                                                                </Typography>
                                                              )}
                                                              {spacesErrors[
                                                                sectionIndex
                                                              ]?.[
                                                                categoryIndex
                                                              ]?.[questionIndex]
                                                                ?.employee_response && (
                                                                <Typography
                                                                  color={
                                                                    "error"
                                                                  }
                                                                >
                                                                  Only Spaces
                                                                  not allowed.
                                                                </Typography>
                                                              )}
                                                              {minCharacterError[
                                                                sectionIndex
                                                              ]?.[
                                                                categoryIndex
                                                              ]?.[questionIndex]
                                                                ?.employee_response && (
                                                                <Typography
                                                                  color={
                                                                    "error"
                                                                  }
                                                                >
                                                                  Minimum 3
                                                                  characters
                                                                  required.
                                                                </Typography>
                                                              )}
                                                            </>
                                                          }
                                                        />
                                                      </Box>
                                                    ) : (
                                                      <Box>
                                                        <Box
                                                          sx={{
                                                            display: "block",
                                                            alignItems:
                                                              "center",
                                                            m: 1,
                                                          }}
                                                        >
                                                          <RadioGroup
                                                            name={`choices_${questionIndex}`}
                                                            value={
                                                              question.employee_response
                                                            }
                                                            onChange={(e) => {
                                                              handleChange(
                                                                sectionIndex,
                                                                categoryIndex,
                                                                questionIndex,
                                                                section.section_id,
                                                                category.category_id,
                                                                question.question_id,
                                                                "employee_response",
                                                                e.target.value,
                                                                question.answer_type
                                                              );
                                                            }}
                                                          >
                                                            {question.answer_choices &&
                                                              question.answer_choices.map(
                                                                (ans: any) => (
                                                                  <FormControlLabel
                                                                    required
                                                                    disabled={
                                                                      !edit
                                                                    }
                                                                    key={
                                                                      ans.option_id
                                                                    }
                                                                    value={
                                                                      ans.answer_value
                                                                    }
                                                                    control={
                                                                      <Radio color="info" />
                                                                    }
                                                                    label={
                                                                      ans.answer_value
                                                                    }
                                                                  />
                                                                )
                                                              )}
                                                            {/* <Divider
                                                          sx={{
                                                            // color: "green",
                                                            height: "1px",
                                                            // backgroundColor: "green",
                                                          }}
                                                        /> */}
                                                          </RadioGroup>
                                                        </Box>
                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                            m: 1,
                                                            p: 1,
                                                          }}
                                                        >
                                                          <Typography
                                                            fontWeight={"bold"}
                                                            paragraph
                                                            sx={{
                                                              width: "11rem",
                                                            }}
                                                          >
                                                            Comments :{" "}
                                                          </Typography>
                                                          <TextField
                                                            name="employee_comment"
                                                            color="info"
                                                            fullWidth
                                                            multiline
                                                            maxRows={4}
                                                            InputProps={{
                                                              readOnly: !edit,
                                                            }}
                                                            style={{
                                                              height: "auto",
                                                              overflow: "auto",
                                                            }}
                                                            value={
                                                              question.employee_comment
                                                            }
                                                            onChange={(e) => {
                                                              handleChange(
                                                                sectionIndex,
                                                                categoryIndex,
                                                                questionIndex,
                                                                section.section_id,
                                                                category.category_id,
                                                                question.question_id,
                                                                "employee_comment",
                                                                e.target.value,
                                                                question.answer_type
                                                              );
                                                            }}
                                                            helperText={
                                                              <>
                                                                {specialCharErrors[
                                                                  sectionIndex
                                                                ]?.[
                                                                  categoryIndex
                                                                ]?.[
                                                                  questionIndex
                                                                ]
                                                                  ?.employee_comment && (
                                                                  <Typography
                                                                    color={
                                                                      "error"
                                                                    }
                                                                  >
                                                                    Special
                                                                    Characters
                                                                    not allowed.
                                                                  </Typography>
                                                                )}
                                                                {minCharacterError[
                                                                  sectionIndex
                                                                ]?.[
                                                                  categoryIndex
                                                                ]?.[
                                                                  questionIndex
                                                                ]
                                                                  ?.employee_comment && (
                                                                  <Typography
                                                                    color={
                                                                      "error"
                                                                    }
                                                                  >
                                                                    Minimum 3
                                                                    characters
                                                                    required.
                                                                  </Typography>
                                                                )}
                                                              </>
                                                            }
                                                          />
                                                        </Box>
                                                      </Box>
                                                    )}
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        m: 1,
                                                        p: 1,
                                                      }}
                                                    >
                                                      <Typography
                                                        fontWeight={"bold"}
                                                        paragraph
                                                        sx={{
                                                          width: "8.5rem",
                                                          mt: 0.5,
                                                        }}
                                                      >
                                                        Rating :{" "}
                                                      </Typography>
                                                      <Rating
                                                        size="large"
                                                        precision={0.5}
                                                        readOnly={!edit}
                                                        onChange={(
                                                          e,
                                                          newValue
                                                        ) => {
                                                          handleChange(
                                                            sectionIndex,
                                                            categoryIndex,
                                                            questionIndex,
                                                            section.section_id,
                                                            category.category_id,
                                                            question.question_id,
                                                            "employee_rating",
                                                            newValue?.toString() ||
                                                              "",
                                                            question.answer_type
                                                          );
                                                        }}
                                                        onChangeActive={(
                                                          event,
                                                          newHover
                                                        ) => {
                                                          // Update the hoverEffect state when the user hovers over the question
                                                          setHoverEffect(
                                                            (
                                                              prevHoverEffect
                                                            ) => {
                                                              const updatedHoverEffect =
                                                                {
                                                                  ...prevHoverEffect,
                                                                };
                                                              // Ensure that the nested objects are initialized properly
                                                              updatedHoverEffect[
                                                                sectionIndex
                                                              ] =
                                                                updatedHoverEffect[
                                                                  sectionIndex
                                                                ] || {};
                                                              updatedHoverEffect[
                                                                sectionIndex
                                                              ][categoryIndex] =
                                                                updatedHoverEffect[
                                                                  sectionIndex
                                                                ][
                                                                  categoryIndex
                                                                ] || {};
                                                              // Set the employee_rating value for the corresponding question
                                                              updatedHoverEffect[
                                                                sectionIndex
                                                              ][categoryIndex][
                                                                questionIndex
                                                              ] = {
                                                                employee_rating:
                                                                  newHover,
                                                              };
                                                              return updatedHoverEffect;
                                                            }
                                                          );
                                                        }}
                                                        value={parseFloat(
                                                          question.employee_rating
                                                        )}
                                                        sx={{
                                                          color: "#1B87C3",
                                                        }}
                                                        aria-required="true"
                                                      />
                                                      {!viewResponse[0]?.response[
                                                        sectionIndex
                                                      ]?.categories[
                                                        categoryIndex
                                                      ]?.questions[
                                                        questionIndex
                                                      ]?.hasOwnProperty(
                                                        "employee_rating"
                                                      ) &&
                                                        flag && (
                                                          <Typography
                                                            sx={{ m: 0.5 }}
                                                            color={"error"}
                                                          >
                                                            This field is
                                                            required.
                                                          </Typography>
                                                        )}
                                                      {value !== null && (
                                                        <Typography
                                                          sx={{
                                                            textAlign: "center",
                                                            ml: 2,
                                                          }}
                                                        >
                                                          {
                                                            labels[
                                                              hoverEffect[
                                                                sectionIndex
                                                              ]?.[
                                                                categoryIndex
                                                              ]?.[questionIndex]
                                                                ?.employee_rating !==
                                                              null
                                                                ? hoverEffect[
                                                                    sectionIndex
                                                                  ]?.[
                                                                    categoryIndex
                                                                  ]?.[
                                                                    questionIndex
                                                                  ]
                                                                    ?.employee_rating
                                                                : value
                                                            ]
                                                          }
                                                        </Typography>
                                                      )}
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              );
                                            }
                                          )}
                                        </Box>
                                      </Box>
                                    );
                                  }
                                )}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      );
                    })}
                  </Box>
                  {edit && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="submit"
                        color="info"
                        size="large"
                        variant="contained"
                        // disabled={!allRatingsFilled}
                        disabled={
                          isSpecialCharError ||
                          isSpacesError ||
                          isMinCharacterError
                        }
                      >
                        Update
                      </Button>
                    </Box>
                  )}
                </FormControl>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default ShowResponseAppraisalForm;
