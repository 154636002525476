import { useEffect, useMemo, useState } from "react";

import { Box, Button, Paper, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { HRDash } from "../../Service/Apis";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { setId } from "../../store/FormSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
} from "../../store/actions";
import NoRowsOverlayComponent from "../../components/Data/NoRowsOverlayComponent";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

const theme = createTheme({
  palette: {
    primary: {
      main: "#006600",
      contrastText: "#fff", //button text white instead of black
    },
    background: {
      default: "#394764",
    },
  },
  typography: {
    fontFamily: ["Signika Negative"].join(","),
    fontSize: 15,
  },
});

type hrComponent = {
  hr_id: string;
  hr_edit_permission: boolean;
  employee_name: string;
  form_name: string;
  manager_average_rating: string;
  manager_name: string;
  response_id: string;
  team_name: string;
  [key: string]: any;
};
interface ResponseType {
  response_id: string;
  form_id: string;
  form_name: string;
  employee_id: string;
  employee_name: string;
  final_response: [
    {
      hr_id: string;
      hr_edit_permission: boolean;
      final_response_id: string;
      manager_average_rating: number;
      response_id: string;
    }
  ];
  response: [
    {
      response_question_id: string;
      question_id: string;
      question_name: string;
      employee_response: string;
      employee_comment: string;
      manager_response: string;
      manager_comment: string;
    }
  ];
}
// ... import statements and component definition

const HrReviewForm = () => {
  const [reviewItems, setReviewItems] = useState<hrComponent[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const token = localStorage.getItem("token");
  const showToast = useCustomToast();

  const axiosInstance = useAxiosInterceptor();

  //takes to a particular form
  const handleGoToReview = (value: any) => {
    dispatch(setId(value));
    localStorage.setItem("formid", value);
    Navigate("/showspecificresponsehr");
  };

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const filteredData = useMemo(() => {
    if (searchTerm === "") {
      return reviewItems;
    }
    return reviewItems.filter((review) =>
      Object.values(review).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [reviewItems, searchTerm]);

  const rows = useMemo(() => {
    return filteredData.map((employee, index) => ({
      ...employee,
      index: index,
    }));
  }, [filteredData]);
  //
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Employee Name",
        field: "employee_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Name
          </strong>
        ),
      },
      {
        headerName: "Form Name",
        field: "form_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Form Name
          </strong>
        ),
      },
      {
        headerName: "Team Name",
        field: "team_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Team Name
          </strong>
        ),
      },
      {
        headerName: "Manager Name",
        field: "manager_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Manager Name
          </strong>
        ),
      },
      {
        headerName: "Employee Average Rating",
        field: "employee_average_rating",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 235,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Average Rating
          </strong>
        ),
      },
      {
        headerName: "Manager Average Rating",
        field: "manager_average_rating",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 235,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Manager Average Rating
          </strong>
        ),
      },
      {
        headerName: "Actions",
        field: "response_id",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 135,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Actions
          </strong>
        ),
        renderCell: (params) => (
          <Button
            color="info"
            size="small"
            variant="contained"
            onClick={() => {
              handleGoToReview(params.row.response_id);
            }}
          >
            Go to Review
          </Button>
        ),
      },
    ],
    []
  );

  //gets all reviews for hr dashboard
  useEffect(() => {
    const response = axiosInstance
      .get(`${HRDash}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setReviewItems(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [count]);

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          // mt: 15,
          // mb: 10,
          width: "98vw",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
          <Typography fontWeight="bold" variant="h3" color={"#1B87C3"}>
            Reviews Submitted
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box>
              <Box
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  m: 2,
                  p: 1,
                  // mr: 5,
                  width: "90vw",
                }}
              >
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    Navigate("/download");
                  }}
                  sx={{ m: 1 }}
                >
                  Go to download
                </Button>
              </Box>
              <Box
                sx={{
                  m: 2,
                  p: 1,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {rows.length === 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 2,
                      }}
                    >
                      <NothingToDisplayComponent />
                    </Box>
                  ) : (
                    <Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
                      <DataGrid
                        autoHeight
                        getRowId={(row) => row.response_id}
                        disableColumnMenu
                        sx={{
                          width: "100%",
                          height: "100%",
                          "--DataGrid-overlayHeight": "50vh",
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pagination
                        pageSizeOptions={[5, 10, 15]}
                        rows={rows}
                        columns={columns}
                        disableColumnSelector
                        disableDensitySelector
                        slots={{
                          toolbar: GridToolbar,
                          noResultsOverlay: CustomNoRowsOverlay,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                            printOptions: { disableToolbarButton: true },
                            style: { margin: "0.5rem" },
                          },
                        }}
                      />  
                    </Paper>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HrReviewForm;
