import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  InputLabel,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import AdminAddEmployee from "../All Employee/AdminAddEmployee";

import { enqueueSnackbar } from "notistack";

import { setEmpId } from "../../store/FormSlice";
import { viewResponse } from "../../Service/Apis";
import { setIsLoading } from "../../store/LoadingSlice";
import Skeletons from "../../components/Skeleton/Skeletons";
import { generateExcelFile } from "../../components/Excel/GenerateExcel";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import Sidebar from "../../components/Page Component/Sidebar";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface ResponseType {
  response_id: string;
  form_id: string;
  form_name: string;
  employee_id: string;
  employee_name: string;
  response: [
    {
      response_question_id: string;
      question_id: string;
      question_name: string;
      emp_response: string;
      emp_comment: string;
      manager_response: string;
      manager_comment: string;
    }
  ];
}

const FinalResponse = () => {
  const [viewModal1, setViewModal1] = useState<number | null>(null);
  const [getEmployeeReview, setGetEmployeeReview] = useState<ResponseType[]>([
    {
      response_id: "",
      form_id: "",
      form_name: "",
      employee_id: "",
      employee_name: "",
      response: [
        {
          response_question_id: "",
          question_id: "",
          question_name: "",
          emp_response: "",
          emp_comment: "",
          manager_response: "",
          manager_comment: "",
        },
      ],
    },
  ]);

  const [review, setReview] = useState([
    {
      response_id: "",
      form_id: "",
      form_name: "",
      employee_id: "",
      employee_name: "",
    },
  ]);

  const [selectedFormResponse, setSelectedFormResponse] =
    useState<ResponseType | null>(null);

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();
  const emp_id = useSelector((state: any) => state.form.empId);

  const isloading = useSelector((state: any) => state.loading.isLoading);

  const form_id = useSelector((state: any) => state.form.id);

  const axiosInstance = useAxiosInterceptor();

  const handleOpenModal1 = (index: number, id: string) => {
    setViewModal1(index);
    dispatch(setEmpId(id));

    // Find the selected form response
    const response = getEmployeeReview.find((data) => data.response_id === id);
    setSelectedFormResponse(response || null);
  };

  const handleCloseModal1 = () => {
    setViewModal1(null);
  };

  const downloadExcel = (index: number) => {
    // if (
    // 	typeof selectedFormResponse === "object" &&
    // 	selectedFormResponse !== null
    // ) {
    // 	generateExcelFile(selectedFormResponse as ResponseType);
    // } else {
    // }
  };

  // useEffect(() => {
  // 	const response = axiosInstance
  // 		.get(`http://localhost:8000/show/responses-meta/?form_id=${form_id}`, {
  // 			headers: {
  // 				"Content-Type": "application/json",
  // 			},
  // 		})
  // 		.then((res) => {
  // 			setReview(res.data);
  // 		})
  // 		.catch((error) => //console.log("Error :", error.message));
  // }, []);

  //shows specific response of a particular form
  useEffect(() => {
    dispatch(setIsLoading(true));
    const response = axiosInstance
      .get(`${viewResponse}${form_id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setGetEmployeeReview(res.data);
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        console.log("Error :", error.message);
      });
  }, []);
  if (isloading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>

      <Box>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 12,
            mb: 10,
            width: "fit-content",
          }}
        >
          <TableContainer
            component={Paper}
            elevation={11}
            sx={{ boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)" }}
          >
            <Table>
              <TableHead sx={{ background: "#e6f2e6" }}>
                <TableRow>
                  <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                    Options
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getEmployeeReview.map((emp: any, index) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        {emp.employee_name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Container sx={{ textAlign: "center" }}>
                          <Button
                            onClick={() => {
                              handleOpenModal1(index, emp.response_id);
                            }}
                            sx={{ color: "#198d19" }}
                          >
                            View Response
                          </Button>
                          <Box>
                            <Button
                              variant="contained"
                              color="info"
                              onClick={() => downloadExcel(index)}
                            >
                              Download Excel
                            </Button>
                          </Box>
                          <Modal
                            open={viewModal1 === index}
                            onClose={handleCloseModal1}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            slotProps={{
                              backdrop: {
                                style: {
                                  backgroundColor: "transparent",
                                  boxShadow: "none",
                                },
                              },
                            }}
                            sx={{}}
                          >
                            <Box
                              sx={{
                                position: "absolute" as "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 400,
                                bgcolor: "background.paper",
                                // border: "2px solid #000",
                                boxShadow: 2,
                                p: 4,
                                overflow: "auto",
                                height: "75%",
                                borderRadius: "2%",
                              }}
                            >
                              {selectedFormResponse && (
                                <>
                                  <Box>
                                    <Box>
                                      <Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            m: 1,
                                          }}
                                        >
                                          <Typography
                                            fontWeight={"bold"}
                                            variant="h5"
                                            sx={{
                                              color: "#66b366",
                                              m: 1,
                                              p: 1,
                                            }}
                                          >
                                            {selectedFormResponse.form_name}
                                          </Typography>
                                        </Box>
                                        <Divider />
                                        {selectedFormResponse.response.map(
                                          (res: any) => {
                                            return (
                                              <Box sx={{ m: 2, p: 1 }}>
                                                <Card
                                                  elevation={11}
                                                  sx={{
                                                    m: 1,
                                                    p: 1,
                                                    backgroundColor: "#e6f2e6",
                                                    boxShadow:
                                                      "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
                                                  }}
                                                >
                                                  <Typography
                                                    fontWeight={"bold"}
                                                    sx={{ p: 1 }}
                                                  >
                                                    {res.question_name}
                                                  </Typography>
                                                  <Typography sx={{ ml: 2 }}>
                                                    {res.emp_response}
                                                  </Typography>
                                                </Card>
                                              </Box>
                                            );
                                          }
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Modal>
                        </Container>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default FinalResponse;
