import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AppraisalEditForm from "../pages/Form Edit/AppraisalEditForm";
import AppraisalForm from "../pages/Form Add/AppraisalForm";
import EmployeePreviousResponses from "../pages/Form View/EmployeePreviousResponses";
import EmployeeSpecificResponse from "../pages/Form View/EmployeeSpecificResponse";
import LogIn from "../pages/Login/LogIn";
import AdminAddEmployee from "../pages/All Employee/AdminAddEmployee";
import AllEmployee from "../pages/All Employee/AllEmployee";
import Dashboard from "../pages/Dashboard/Dashboard";
import FillOrSaveAppraisalForm from "../pages/Form Fill/FillOrSaveAppraisalForm";
import AppraisalViewForm from "../pages/Form View/AppraisalViewForm";
import CreatedFroms from "../pages/Form View/CreatedFroms";
import ManagerAppraisal from "../pages/Manager Form Fill/ManagerAppraisal";
import Permission from "../pages/Permission/Permission";
import Profile from "../pages/Profile/Profile";
import Ranking from "../pages/Ranking/Ranking";
import FinalResponse from "../pages/Review/FinalResponse";
import ReviewDownload from "../pages/Review/ReviewDownload";
import ReviewDownloadAdmin from "../pages/Review/ReviewDownloadAdmin";
import ReviewForm from "../pages/Dashboard/ReviewForm";
import ShowAppraisalSpecificAdmin from "../pages/Review/ShowAppraisalSpecificAdmin";
import ShowAppraisalSpecificHr from "../pages/Review/ShowAppraisalSpecificHr";
import ShowResponseAppraisalForm from "../pages/Review/ShowResponseAppraisalForm";
import SuperAdminreview from "../pages/Review/SuperAdminreview";
import Subordinates from "../pages/Subordinate/Subordinates";
import CreateTeam from "../pages/Team/CreateTeam";
import AddEmployee from "../pages/All Employee/AddEmployee";
import ViewEmployee from "../pages/All Employee/ViewEmployee";
import AddTeam from "../pages/Team/AddTeam";
import EditTeam from "../pages/Team/EditTeam";
import ViewTeam from "../pages/Team/ViewTeam";
import HrAndAdminRanking from "../pages/Ranking/HrAndAdminRanking";
import ForgotPassword from "../components/Forgot Password/ForgotPassword";
import FormAdd from "../pages/Form Add/FormAdd";
import AllCreatedForms from "../pages/Form View/AllCreatedForms";
import AllPreviousResponses from "../pages/Form View/AllPreviousResponses";
import AllFormView from "../pages/Form View/AllFormView";
import EditAllForms from "../pages/Form Edit/EditAllForms";
import AllFillForm from "../pages/Form Fill/AllFillForm";
import ShowThreeSixtyFormReview from "../pages/Review/ThreeSixtyForm/ShowThreeSixtyFormReview";
import ThreeSixtySpecificPreviousResponse from "../pages/Form View/ThreeSixtyForm/ThreeSixtySpecificPreviousResponse";
import ProfileSectionLayout from "../pages/Profile/ProfileSectionLayout";
import SkillsAddOrEdit from "../pages/Skill Set/SkillsAddOrEdit";
import ShowFeedBackFormReview from "../pages/Review/FeedBackForm/ShowFeedBackFormReview";

type navbar = {
  label: string;
  route: string;
};

export const Link = () => {
  const islogged = useSelector((state: any) => state.auth.isLoggedIn);
  return (
    <Routes>
      <Route path="/login" element={islogged ? <Dashboard /> : <LogIn />} />
      {/* <Route path="/" element={<Dashboard />} /> */}
      <Route path="/createteam" element={<CreateTeam />} />
      <Route path="/addTeam" element={<AddTeam />} />
      <Route path="/editTeam" element={<EditTeam />} />
      <Route path="/viewTeam" element={<ViewTeam />} />
      <Route path="/managerreview" element={<ManagerAppraisal />} />
      <Route path="/HrRanking" element={<HrAndAdminRanking />} />
      <Route path="/editform" element={<EditAllForms />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/addForm" element={<FormAdd />} />
      <Route path="/createdforms" element={<AllCreatedForms />} />
      <Route path="/fillForm" element={<AllFillForm />} />
      <Route path="viewform" element={<AllFormView />} />
      <Route path="/employeeinformation" element={<AllEmployee />} />
      <Route path="addEmployee" element={<AddEmployee />} />
      <Route path="viewEmployee" element={<ViewEmployee />} />
      <Route path="/addemployee" element={<AdminAddEmployee />} />
      <Route path="/Ranking" element={<Ranking />} />
      <Route path="/HARanking" element={<HrAndAdminRanking />} />
      <Route path="/reviewForm" element={<ReviewForm />} />
      <Route path="/reviews" element={<SuperAdminreview />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/final" element={<FinalResponse />} />
      <Route path="/subordinate" element={<Subordinates />} />
      <Route path="/permission" element={<Permission />} />
      <Route path="/logout" element={<LogIn />} />
      <Route path="/showresponse" element={<ShowResponseAppraisalForm />} />
      <Route
        path="/showspecificresponse"
        element={<ShowAppraisalSpecificAdmin />}
      />
      <Route
        path="/showspecificresponsehr"
        element={<ShowAppraisalSpecificHr />}
      />
      <Route path="/download" element={<ReviewDownload />} />
      <Route path="/previousresponses" element={<AllPreviousResponses />} />

      <Route path="/downloadadmin" element={<ReviewDownloadAdmin />} />
      <Route
        path="/showthreesixtyformresponse"
        element={<ShowThreeSixtyFormReview />}
      />
      <Route
        path="/employeepreviousresponse"
        element={<EmployeeSpecificResponse />}
      />
      <Route
        path="/employee360previousresponse"
        element={<ThreeSixtySpecificPreviousResponse />}
      />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/addoreditskills" element={<SkillsAddOrEdit />} />
      <Route path="/showfeedbackresponse" element={<ShowFeedBackFormReview />} />
    </Routes>
  );
};
