import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
} from "@mui/material";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

import { ViewAllResponses, adminEditAccess } from "../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { labels } from "../Dashboard/ReviewForm";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { setIsLoading } from "../../store/LoadingSlice";
import Skeletons from "../../components/Skeleton/Skeletons";
import { postDataSuccess } from "../../store/AxiosSlice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",  
  // boxShadow: 24,
  overflow: "auto",
  p: 4,
  borderRadius: "5px",
};

interface ResponseType {
  response_id: string;
  form_team_id: string;
  form_id: string;
  form_name: string;
  form_description: string;
  team_id: string;
  project_name: string;
  employee_id: string;
  roles: string[];
  employee_name: string;
  employee_code: string;
  employee_designation: string;
  employee_deadline: string;
  manager_name: string;
  manager_deadline: string;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  response: {
    section_id: string;
    section: string;
    categories: {
      category_id: string;
      category: string;
      questions: {
        question_id: string;
        question_name: string;
        response_question_id: string;
        answer_type: string;
        answer_choices: [
          {
            option_id: string;
            answer_value: string;
          }
        ];
        employee_rating: string;
        employee_response: string;
        employee_comment: string;
        manager_response: string | null;
        manager_comment: string | null;
      }[];
    }[];
  }[];
  final_response: {
    final_response_id: string;
    response_id: string;
    manager_average_rating: number | null;
    hr_edit_permission: boolean;
    hr_id: string;
  }[];
}

interface EditResponseType {
  question_id: string;
  question_name: string;
  response_question_id: string;
  answer_type: string;
  answer_choices: { answer_value: string; option_id: string }[]; // Updated this line
  employee_rating: string;
  employee_response: string;
  employee_comment: string;
  manager_response: string | null;
  manager_comment: string | null;
}
const initialHoverEffect: HoverEffect = {};

interface HoverEffect {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_rating: number;
      };
    };
  };
}
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ShowAppraisalSpecificAdmin = () => {
  const [selectedFormResponse, setSelectedFormResponse] = useState<
    ResponseType[] | null
  >(null);

  const [openEdit, setOpenEdit] = useState<{
    sectionIndex: number | null;
    categoryIndex: number | null;
    questionIndex: number | null;
  }>({ sectionIndex: null, categoryIndex: null, questionIndex: null });

  const [editResponse, setEditResponse] = useState<EditResponseType>({
    question_id: "",
    question_name: "",
    response_question_id: "",
    answer_type: "",
    answer_choices: [], // Initialize as an empty array of objects
    employee_rating: "",
    employee_response: "",
    employee_comment: "",
    manager_response: "",
    manager_comment: "",
  });

  const [responseid, setresponseid] = useState("");
  const [count, setCount] = useState<number>(0);
  const [values, setValues] = React.useState<number | null>(0);
  const [hover, setHover] = useState<number[]>([]);
  const [mHover, setMHover] = useState<number[]>([]);
  const [rating, setRating] = useState({
    employee_rating: false,
    manager_response: false,
  });
  const [hoverEffect, setHoverEffect] =
    useState<HoverEffect>(initialHoverEffect);
  const [mhoverEffect, setMHoverEffect] =
    useState<HoverEffect>(initialHoverEffect);
  const [showError, setShowError] = useState(false);

  const Navigate = useNavigate();
  // const success = useSelector((state: any) => state.http.data);

  const showToast = useCustomToast();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();
  const axiosInstance = useAxiosInterceptor();

  const value =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");

  const isLoading = useSelector((state: any) => state.loading.isLoading);

  const token = localStorage.getItem("token");

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleOpenEdit = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number
  ) => {
    if (selectedFormResponse && selectedFormResponse[0]?.response) {
      const editedResponse =
        selectedFormResponse[0]?.response[sectionIndex]?.categories?.[
          categoryIndex
        ]?.questions?.[questionIndex];
      if (editedResponse) {
        setEditResponse({ ...editedResponse });
        setOpenEdit({ sectionIndex, categoryIndex, questionIndex });
      }
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit({
      sectionIndex: null,
      categoryIndex: null,
      questionIndex: null,
    });
    setRating({
      employee_rating: false,
      manager_response: false,
    });
  };

  const handleEditResponse = (e: any) => {
    e.preventDefault();
  };

  // Edit employee and manager response.
  const handleSaveEdit = (e: any, question_id: string) => {
    e.preventDefault();
    if (rating.employee_rating || rating.manager_response) {
      handleShowToast("Please fill all fields.", "error", true);
      return;
    }
    var data = {
      employee_response: editResponse.employee_response,
      employee_comment: editResponse.employee_comment,
      employee_rating: editResponse.employee_rating,
      manager_response: editResponse.manager_response,
      manager_comment: editResponse.manager_comment,
    };

    // var question_id = editResponse.question_id;
    try {
      const response = dispatch2(
        putData(
          `${adminEditAccess}${
            selectedFormResponse && selectedFormResponse[0]?.response_id
          }&question_id=${question_id}`,
          data
        )
      )
        .then((success) => {
          handleShowToast("Successfully Updated !", "success", true);
          setCount(count + 1);
          handleCloseEdit();
          dispatch(postDataSuccess(null));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditChange = (e: any) => {
    const { name, value } = e.target;
    setEditResponse({ ...editResponse, [name]: value });
    if (value === undefined || null || 0) {
      setRating({ ...rating, employee_rating: true });
    }
  };

  const handleRatingEdit = (value: any) => {
    if (value === undefined || value === null || (value === 0 && showError)) {
      setRating({
        ...rating,
        manager_response: true,
      });
    } else {
      setRating({
        ...rating,
        manager_response: false,
      });
    }
    setEditResponse({
      ...editResponse,
      manager_response: value?.toString(),
    });
  };

  const handleEmpRating = (value: any) => {
    if (value === undefined || value === null) {
      setRating({
        ...rating,
        employee_rating: true,
      });
    } else {
      setRating({
        ...rating,
        employee_rating: false,
      });
    }
    setEditResponse({
      ...editResponse,
      employee_rating: value?.toString(),
    });
  };

  useEffect(() => {
    // If rating.employee_rating is true, set showError to true after 2000 milliseconds (2 seconds)
    if (rating.employee_rating) {
      const timer = setTimeout(() => {
        setShowError(true);
      }, 2000);

      // Clear the timer when the component unmounts or when rating.employee_rating changes to false
      return () => clearTimeout(timer);
    } else {
      // If rating.employee_rating is false, hide the error message immediately
      setShowError(false);
    }
  }, [rating.employee_rating]);

  // API call to fetch particular response.
  useEffect(() => {
    const fetchResponses = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${ViewAllResponses}?response_id=${value}`)
        );
        setSelectedFormResponse(response as ResponseType[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchResponses();
  }, [count]);

  if (isLoading) {
    return <Skeletons />;
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box
        sx={{
          mt: 12,
          mb: 10,
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
          width: "98vw",
        }}
      >
        {selectedFormResponse &&
          selectedFormResponse.map((res: ResponseType, formIndex: number) => (
            <Box
              // component="form"
              // onSubmit={(e) => {
              //   handleSubmit(e);
              //   // setFormTeamId(res.teams[0].form_team_id);
              // }}
              key={res.team_id}
              sx={{ width: "75%" }}
            >
              <FormControl>
                <Box
                  sx={{
                    mt: 1,
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    fontWeight={"bold"}
                    letterSpacing={3}
                    variant="h4"
                    sx={{ textDecoration: "underline" }}
                  >
                    {res.form_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    m: 1,
                    //   mt: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5">
                    {res.form_description ? `(${res.form_description})` : ""}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                      <Typography sx={{ width: "9rem" }} fontWeight={"bold"}>
                        Appraisee :{" "}
                      </Typography>
                      <Typography> {res.employee_name}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                      <Typography sx={{ width: "9rem" }} fontWeight={"bold"}>
                        Manager Name :{" "}
                      </Typography>
                      <Typography>{res.manager_name}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                      <Typography sx={{ width: "9rem" }} fontWeight={"bold"}>
                        Employee Code :{" "}
                      </Typography>
                      <Typography>{res.employee_code}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                      <Typography sx={{ width: "9rem" }} fontWeight={"bold"}>
                        Designation :{" "}
                      </Typography>
                      <Typography>{res.employee_designation}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box>
                  {res.response.map((section: any, sectionIndex: number) => {
                    return (
                      <Box sx={{ m: 2, width: "75vw" }}>
                        <Accordion sx={{ m: 2, p: 0 }}>
                          <AccordionSummary sx={{ width: "100%" }}>
                            <Box sx={{}}>
                              <Typography variant="h5" fontWeight={"bold"}>
                                {String.fromCharCode(
                                  sectionIndex + "A".charCodeAt(0)
                                ) +
                                  ") " +
                                  section.section}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{ m: 2, p: 2 }}>
                              {section.categories.map(
                                (category: any, categoryIndex: number) => {
                                  return (
                                    <Box>
                                      <Box>
                                        <Typography
                                          variant="h5"
                                          fontWeight={"bold"}
                                        >
                                          {String.fromCharCode(
                                            categoryIndex + "a".charCodeAt(0)
                                          ) +
                                            ". " +
                                            category.category}
                                        </Typography>
                                      </Box>
                                      <Box sx={{ m: 2, p: 2 }}>
                                        {category.questions.map(
                                          (
                                            question: any,
                                            questionIndex: number
                                          ) => {
                                            return (
                                              <Box key={question.question_id}>
                                                <Box>
                                                  <Typography
                                                    fontWeight={"bold"}
                                                    fontSize={19}
                                                  >
                                                    {questionIndex + 1 + "."}{" "}
                                                    {question.question_name}
                                                  </Typography>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignContent: "right",
                                                      justifyContent: "right",
                                                    }}
                                                  >
                                                    <Button
                                                      size="small"
                                                      color="info"
                                                      onClick={(e) => {
                                                        // e.preventDefault();
                                                        handleOpenEdit(
                                                          sectionIndex,
                                                          categoryIndex,
                                                          questionIndex
                                                        );

                                                        handleEditResponse(e);
                                                      }}
                                                    >
                                                      Edit
                                                    </Button>
                                                    <Modal
                                                      open={
                                                        openEdit.sectionIndex ===
                                                          sectionIndex &&
                                                        openEdit.categoryIndex ===
                                                          categoryIndex &&
                                                        openEdit.questionIndex ===
                                                          questionIndex
                                                      }
                                                      onClose={handleCloseEdit}
                                                      aria-labelledby="modal-modal-title"
                                                      aria-describedby="modal-modal-description"
                                                      sx={{
                                                        overflow: "scroll",
                                                      }}
                                                    >
                                                      <Card sx={style}>
                                                        <Box
                                                          component="form"
                                                          onSubmit={(e) => {
                                                            handleSaveEdit(
                                                              e,
                                                              question.question_id
                                                            );
                                                          }}
                                                        >
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "flex-end",
                                                              alignItems:
                                                                "flex-end",
                                                            }}
                                                          >
                                                            <IconButton
                                                              onClick={() =>
                                                                handleCloseEdit()
                                                              }
                                                            >
                                                              <CloseIcon />
                                                            </IconButton>
                                                          </Box>
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              alignItems:
                                                                "center",
                                                              m: 1,
                                                              p: 1,
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                width: "15rem",
                                                              }}
                                                            >
                                                              Employee Response
                                                              :
                                                            </Typography>
                                                            <TextField
                                                              color="info"
                                                              size="small"
                                                              maxRows={4}
                                                              multiline
                                                              sx={{
                                                                width: "15rem",
                                                                overflow:
                                                                  "auto",
                                                              }}
                                                              value={
                                                                editResponse.employee_response
                                                              }
                                                              name="employee_response"
                                                              onChange={
                                                                handleEditChange
                                                              }
                                                            />
                                                          </Box>
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "left",
                                                              alignItems:
                                                                "center",
                                                              m: 1,
                                                              p: 1,
                                                              ml: { md: 2 },
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                width: "15rem",
                                                              }}
                                                            >
                                                              Employee Rating :
                                                            </Typography>
                                                            <Box>
                                                              <Box
                                                                sx={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  m: 2,
                                                                  justifyContent:
                                                                    "right",
                                                                }}
                                                              >
                                                                <Rating
                                                                  precision={
                                                                    0.5
                                                                  }
                                                                  emptyIcon={
                                                                    <StarIcon
                                                                      style={{
                                                                        opacity: 0.55,
                                                                      }}
                                                                      fontSize="inherit"
                                                                    />
                                                                  }
                                                                  size="large"
                                                                  sx={{
                                                                    // width: "15rem",
                                                                    color:
                                                                      "#1B87C3",
                                                                  }}
                                                                  value={parseFloat(
                                                                    editResponse.employee_rating
                                                                  )}
                                                                  onChange={(
                                                                    e,
                                                                    value
                                                                  ) => {
                                                                    handleEmpRating(
                                                                      value
                                                                    );
                                                                  }}
                                                                  onChangeActive={(
                                                                    event,
                                                                    newHover
                                                                  ) => {
                                                                    // Update the hoverEffect state when the user hovers over the question
                                                                    setMHoverEffect(
                                                                      (
                                                                        prevHoverEffect
                                                                      ) => {
                                                                        const updatedHoverEffect =
                                                                          {
                                                                            ...prevHoverEffect,
                                                                          };
                                                                        // Ensure that the nested objects are initialized properly
                                                                        updatedHoverEffect[
                                                                          sectionIndex
                                                                        ] =
                                                                          updatedHoverEffect[
                                                                            sectionIndex
                                                                          ] ||
                                                                          {};
                                                                        updatedHoverEffect[
                                                                          sectionIndex
                                                                        ][
                                                                          categoryIndex
                                                                        ] =
                                                                          updatedHoverEffect[
                                                                            sectionIndex
                                                                          ][
                                                                            categoryIndex
                                                                          ] ||
                                                                          {};
                                                                        // Set the employee_rating value for the corresponding question
                                                                        updatedHoverEffect[
                                                                          sectionIndex
                                                                        ][
                                                                          categoryIndex
                                                                        ][
                                                                          questionIndex
                                                                        ] = {
                                                                          employee_rating:
                                                                            newHover,
                                                                        };

                                                                        // Perform the validation for hover effect too
                                                                        const hoverValue =
                                                                          newHover !==
                                                                          undefined
                                                                            ? newHover
                                                                            : rating.employee_rating;

                                                                        return updatedHoverEffect;
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                              </Box>
                                                              {values !==
                                                                null && (
                                                                <Typography
                                                                  fontSize={14}
                                                                  sx={{
                                                                    textAlign:
                                                                      "center",
                                                                    ml: 2,
                                                                  }}
                                                                >
                                                                  {
                                                                    labels[
                                                                      mhoverEffect[
                                                                        sectionIndex
                                                                      ]?.[
                                                                        categoryIndex
                                                                      ]?.[
                                                                        questionIndex
                                                                      ]
                                                                        ?.employee_rating !==
                                                                      null
                                                                        ? mhoverEffect[
                                                                            sectionIndex
                                                                          ]?.[
                                                                            categoryIndex
                                                                          ]?.[
                                                                            questionIndex
                                                                          ]
                                                                            ?.employee_rating
                                                                        : value
                                                                    ]
                                                                  }
                                                                </Typography>
                                                              )}
                                                              {rating.employee_rating && (
                                                                <Fade
                                                                  in={
                                                                    rating.employee_rating
                                                                  }
                                                                >
                                                                  <Typography
                                                                    fontSize={
                                                                      14
                                                                    }
                                                                    color={
                                                                      "error"
                                                                    }
                                                                    sx={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    This field
                                                                    is required.
                                                                  </Typography>
                                                                </Fade>
                                                              )}
                                                            </Box>
                                                          </Box>
                                                          {editResponse.answer_type.toLowerCase() ===
                                                            "multichoice" && (
                                                            <Box
                                                              sx={{
                                                                display: "flex",
                                                                justifyContent:
                                                                  "center",
                                                                alignItems:
                                                                  "center",
                                                                m: 1,
                                                                p: 1,
                                                              }}
                                                            >
                                                              <Typography
                                                                sx={{
                                                                  width:
                                                                    "15rem",
                                                                }}
                                                              >
                                                                Employee Comment
                                                                :
                                                              </Typography>
                                                              <TextField
                                                                color="info"
                                                                multiline
                                                                // rows={2}
                                                                maxRows={4}
                                                                size="small"
                                                                sx={{
                                                                  width:
                                                                    "15rem",
                                                                  overflow:
                                                                    "auto",
                                                                }}
                                                                value={
                                                                  editResponse.employee_comment
                                                                }
                                                                name="employee_comment"
                                                                onChange={
                                                                  handleEditChange
                                                                }
                                                              />
                                                            </Box>
                                                          )}
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "left",
                                                              alignItems:
                                                                "center",
                                                              m: 1,
                                                              p: 1,
                                                              ml: { md: 2 },
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                width: "15rem",
                                                              }}
                                                            >
                                                              Manager Response :
                                                            </Typography>
                                                            <Box>
                                                              <Box
                                                                sx={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  m: 2,
                                                                  justifyContent:
                                                                    "right",
                                                                }}
                                                              >
                                                                <Rating
                                                                  precision={
                                                                    0.5
                                                                  }
                                                                  emptyIcon={
                                                                    <StarIcon
                                                                      style={{
                                                                        opacity: 0.55,
                                                                      }}
                                                                      fontSize="inherit"
                                                                    />
                                                                  }
                                                                  size="large"
                                                                  sx={{
                                                                    // width: "15rem",
                                                                    color:
                                                                      "#1B87C3",
                                                                  }}
                                                                  value={parseFloat(
                                                                    editResponse.manager_response as string
                                                                  )}
                                                                  onChange={(
                                                                    e,
                                                                    value
                                                                  ) => {
                                                                    handleRatingEdit(
                                                                      value
                                                                    );
                                                                  }}
                                                                  onChangeActive={(
                                                                    event,
                                                                    newHover
                                                                  ) => {
                                                                    // Update the hoverEffect state when the user hovers over the question
                                                                    setHoverEffect(
                                                                      (
                                                                        prevHoverEffect
                                                                      ) => {
                                                                        const updatedHoverEffect =
                                                                          {
                                                                            ...prevHoverEffect,
                                                                          };
                                                                        // Ensure that the nested objects are initialized properly
                                                                        updatedHoverEffect[
                                                                          sectionIndex
                                                                        ] =
                                                                          updatedHoverEffect[
                                                                            sectionIndex
                                                                          ] ||
                                                                          {};
                                                                        updatedHoverEffect[
                                                                          sectionIndex
                                                                        ][
                                                                          categoryIndex
                                                                        ] =
                                                                          updatedHoverEffect[
                                                                            sectionIndex
                                                                          ][
                                                                            categoryIndex
                                                                          ] ||
                                                                          {};
                                                                        // Set the employee_rating value for the corresponding question
                                                                        updatedHoverEffect[
                                                                          sectionIndex
                                                                        ][
                                                                          categoryIndex
                                                                        ][
                                                                          questionIndex
                                                                        ] = {
                                                                          employee_rating:
                                                                            newHover,
                                                                        };

                                                                        // Perform the validation for hover effect too
                                                                        const hoverValue =
                                                                          newHover !==
                                                                          undefined
                                                                            ? newHover
                                                                            : rating.manager_response;

                                                                        return updatedHoverEffect;
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                              </Box>
                                                              {values !==
                                                                null && (
                                                                <Typography
                                                                  fontSize={14}
                                                                  sx={{
                                                                    textAlign:
                                                                      "center",
                                                                    ml: 2,
                                                                  }}
                                                                >
                                                                  {
                                                                    labels[
                                                                      hoverEffect[
                                                                        sectionIndex
                                                                      ]?.[
                                                                        categoryIndex
                                                                      ]?.[
                                                                        questionIndex
                                                                      ]
                                                                        ?.employee_rating !==
                                                                      null
                                                                        ? hoverEffect[
                                                                            sectionIndex
                                                                          ]?.[
                                                                            categoryIndex
                                                                          ]?.[
                                                                            questionIndex
                                                                          ]
                                                                            ?.employee_rating
                                                                        : value
                                                                    ]
                                                                  }
                                                                </Typography>
                                                              )}
                                                              {rating.manager_response && (
                                                                <Fade
                                                                  in={
                                                                    rating.manager_response
                                                                  }
                                                                >
                                                                  <Typography
                                                                    fontSize={
                                                                      14
                                                                    }
                                                                    color={
                                                                      "error"
                                                                    }
                                                                    sx={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    This field
                                                                    is required.
                                                                  </Typography>
                                                                </Fade>
                                                              )}
                                                            </Box>
                                                          </Box>
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              alignItems:
                                                                "center",
                                                              m: 1,
                                                              p: 1,
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                width: "15rem",
                                                              }}
                                                            >
                                                              Manager Comment :
                                                            </Typography>
                                                            <TextField
                                                              color="info"
                                                              multiline
                                                              // rows={2}
                                                              maxRows={4}
                                                              size="small"
                                                              sx={{
                                                                width: "15rem",
                                                                overflow:
                                                                  "auto",
                                                              }}
                                                              value={
                                                                editResponse.manager_comment
                                                              }
                                                              name="manager_comment"
                                                              onChange={
                                                                handleEditChange
                                                              }
                                                            />
                                                          </Box>
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              m: 2,
                                                            }}
                                                          >
                                                            <Button
                                                              size="small"
                                                              type="submit"
                                                              variant="contained"
                                                              color="info"
                                                              // onClick={(e) => {
                                                              //   handleSaveEdit(
                                                              //     e,
                                                              //     data.question_id
                                                              //   );
                                                              // }}
                                                            >
                                                              Save
                                                            </Button>
                                                          </Box>
                                                        </Box>
                                                      </Card>
                                                    </Modal>
                                                  </Box>
                                                </Box>

                                                <Box sx={{ ml: 2, mr: 2 }}>
                                                  {question.answer_type ===
                                                  "short_answer" ? (
                                                    <Box>
                                                      <TextField
                                                        required
                                                        name={`employee_response`}
                                                        size="small"
                                                        fullWidth
                                                        color="info"
                                                        maxRows={4}
                                                        multiline
                                                        inputProps={{
                                                          minLength: 3,
                                                          readonly: "true",
                                                        }}
                                                        value={
                                                          question.employee_response
                                                        }
                                                        sx={{
                                                          m: 2,
                                                          overflow: "auto",
                                                        }}
                                                        style={{
                                                          height: "auto",
                                                        }}
                                                        InputProps={{
                                                          style: {
                                                            minHeight: "unset",
                                                          },
                                                        }}
                                                      />
                                                    </Box>
                                                  ) : (
                                                    <Box>
                                                      <Box
                                                        sx={{
                                                          display: "block",
                                                          alignItems: "center",
                                                          m: 1,
                                                        }}
                                                      >
                                                        <RadioGroup
                                                          name={`employee_response`}
                                                          value={
                                                            question.employee_response
                                                          }
                                                        >
                                                          {question.answer_choices &&
                                                            question.answer_choices.map(
                                                              (ans: any) => (
                                                                <FormControlLabel
                                                                  required
                                                                  key={
                                                                    ans.answer_id
                                                                  }
                                                                  value={
                                                                    ans.answer_value
                                                                  }
                                                                  control={
                                                                    <Radio color="info" />
                                                                  }
                                                                  label={
                                                                    ans.answer_value
                                                                  }
                                                                />
                                                              )
                                                            )}
                                                        </RadioGroup>
                                                      </Box>
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          m: 1,
                                                          p: 1,
                                                        }}
                                                      >
                                                        <Typography
                                                          fontWeight={"bold"}
                                                          paragraph
                                                          sx={{
                                                            width: "11rem",
                                                          }}
                                                        >
                                                          Comments :{" "}
                                                        </Typography>
                                                        <TextField
                                                          name="employee_comment"
                                                          color="info"
                                                          fullWidth
                                                          maxRows={4}
                                                          multiline
                                                          // rows={2}
                                                          style={{
                                                            height: "auto",
                                                            overflow: "auto",
                                                          }}
                                                          value={
                                                            question.employee_comment
                                                          }
                                                        />
                                                      </Box>
                                                    </Box>
                                                  )}
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      m: 1,
                                                      p: 1,
                                                    }}
                                                  >
                                                    <Typography
                                                      fontWeight={"bold"}
                                                      paragraph
                                                      sx={{
                                                        width: "8.5rem",
                                                        mt: 0.5,
                                                      }}
                                                    >
                                                      Rating :{" "}
                                                    </Typography>
                                                    <Rating
                                                      size="large"
                                                      precision={0.5}
                                                      value={parseFloat(
                                                        question.employee_rating
                                                      )}
                                                      readOnly
                                                      sx={{
                                                        color: "#1B87C3",
                                                      }}
                                                      aria-required="true"
                                                    />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      m: 1,
                                                      p: 1,
                                                    }}
                                                  >
                                                    <Typography
                                                      fontWeight={"bold"}
                                                      paragraph
                                                      sx={{
                                                        width: "8.5rem",
                                                        mt: 0.5,
                                                      }}
                                                    >
                                                      Manager Rating :{" "}
                                                    </Typography>
                                                    <Rating
                                                      size="large"
                                                      precision={0.5}
                                                      value={parseFloat(
                                                        question.manager_response
                                                      )}
                                                      readOnly
                                                      sx={{
                                                        color: "#1B87C3",
                                                      }}
                                                      aria-required="true"
                                                    />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      m: 1,
                                                      p: 1,
                                                    }}
                                                  >
                                                    <Typography
                                                      fontWeight={"bold"}
                                                      paragraph
                                                      sx={{ width: "11rem" }}
                                                    >
                                                      Manager Comments :{" "}
                                                    </Typography>
                                                    <TextField
                                                      name="manager_comment"
                                                      color="info"
                                                      fullWidth
                                                      multiline
                                                      // rows={2}
                                                      maxRows={4}
                                                      style={{
                                                        height: "auto",
                                                        overflow: "auto",
                                                      }}
                                                      inputProps={{
                                                        readonly: "true",
                                                      }}
                                                      value={
                                                        question.manager_comment
                                                      }
                                                    />
                                                  </Box>
                                                </Box>
                                              </Box>
                                            );
                                          }
                                        )}
                                      </Box>
                                    </Box>
                                  );
                                }
                              )}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    );
                  })}
                </Box>
                {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    color="info"
                    size="large"
                    variant="contained"
                    // disabled={!allRatingsFilled}
                  >
                    Submit
                  </Button>
                </Box> */}
              </FormControl>
            </Box>
          ))}
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default ShowAppraisalSpecificAdmin;
