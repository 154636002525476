import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxiosInterceptor from "../../../components/layout/AxiosInstance";
import {
  ViewAllResponses,
  editEmployeeSelfResponse,
  fillFormany,
  finalSubmit,
  getResponseId,
  listFeedbackForm,
  retrieveEmployeeFeedbackResponse,
  updateEmployeeFeedback,
  viewEmployeeSelfResponse,
  viewResponse,
} from "../../../Service/Apis";
import { useDispatch, useSelector } from "react-redux";
import FIllHeiForm from "../FIllHeiForm";
import SaveFillForm from "../SaveFillForm";
import { setResponseId } from "../../../store/FormSlice";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  patchData,
  postData,
  postDataSuccess,
  putData,
} from "../../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../store/Store";
import Footer from "../../../components/Page Component/Footer";
import Navbar from "../../../components/Page Component/Navbar";
import { labels } from "../../Dashboard/ReviewForm";
import { setInPageLoading, setIsLoading } from "../../../store/LoadingSlice";
import { Navigate, useNavigate } from "react-router-dom";
import {
  ToastVariant,
  useCustomToast,
} from "../../../components/Methods/SnackBar";

interface ResponseId {
  response_id: string;
}

interface FeedbackResponseType {
  response_id: string;
  created_by: string;
  employee_deadline: string;
  form_description: string;
  form_id: string;
  form_name: string;
  is_360: boolean;
  is_appraisal: boolean;
  is_employee_feedback: boolean;
  is_hierarchical: boolean;
  is_manager_feedback: boolean;
  final_submit_done: boolean;
  is_uploaded: boolean;
  manager_deadline: string;
  questions_old_format: Question[];
  employee_feedback: string;
}

interface Question {
  answer_choices: [
    {
      option_id: string;
      answer_value: string;
    }
  ];
  answer_type: string;
  category_id: string | null;
  form_id: string;
  question: string;
  question_id: string;
  employee_response: string;
  employee_comment: string;
  employee_rating: string;
  manager_response: string;
  manager_comment: string;
  manager_rating: string;
}

interface Response1 {
  question_id: string;
  employee_response: string;
  employee_comment: string;
  employee_rating: string;
}

interface SpecialCharErrors {
  [questionIndex: number]: {
    employee_response: boolean;
    employee_comment: boolean;
  };
}

interface MinCharacterError {
  [questionIndex: number]: {
    employee_response: boolean;
    employee_comment: boolean;
  };
}

interface SpacesErrors {
  [questionIndex: number]: {
    employee_response: boolean;
    employee_comment: boolean;
  };
}

interface HoverEffect {
  [questionIndex: number]: {
    employee_rating: number;
  };
}
const initialSpecialCharErrors: SpecialCharErrors = {};
const initialMinCharacterErrors: MinCharacterError = {};
const initialSpacesErrors: SpacesErrors = {};
const initialHoverEffect: HoverEffect = {};

const FeedBackFillFOrm = () => {
  const [responseIds, setResponseIds] = useState<ResponseId | null>(null);
  const [viewResponse, setViewResponse] = useState<FeedbackResponseType[]>([]);
  const [specialCharErrors, setSpecialCharErrors] = useState<SpecialCharErrors>(
    initialSpecialCharErrors
  );
  const [hoverEffect, setHoverEffect] =
    useState<HoverEffect>(initialHoverEffect);

  const [spacesErrors, setSpacesErrors] =
    useState<SpacesErrors>(initialSpacesErrors);
  const [minCharacterError, setMinCharacterError] = useState<MinCharacterError>(
    initialMinCharacterErrors
  );
  const [isSpecialCharError, setIsSpecialCharError] = useState<boolean>(false);

  const [isSpacesError, setIsSpacesError] = useState<boolean>(false);
  const [isMinCharacterError, setIsMinCharacterError] =
    useState<boolean>(false);
  const [value, setValue] = useState<number | null>(0);

  const [flag, setFlag] = useState<boolean>(false);
  const [flag2, setFlag2] = useState<boolean>(false);
  const [empRating, setEmpRating] = useState<boolean[]>([]);

  const token = localStorage.getItem("token");

  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");

  const formTeamId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const feedbackRole =
    JSON.parse(
      localStorage.getItem("feedbackRole") ||
        "{is_employee_feedback : false, is_manager_feedback : false}"
    ) || "";

  const feedbackFormEmployeeId = localStorage.getItem("employeeId");

  const axiosInstance = useAxiosInterceptor();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();
  const navigate = useNavigate();

  const responseId = useSelector((state: any) => state.form.response_id);

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };
  // Check for spaces errors.
  const validateSpacesError = () => {
    const containsOnlySpaces = /^\s*$/;
    viewResponse[0].questions_old_format.forEach((question, questionIndex) => {
      const { employee_response, employee_comment } = question;

      if (
        (employee_response && employee_response.trim().length === 0) ||
        containsOnlySpaces.test(employee_response)
      ) {
        spacesErrors[questionIndex] = {
          employee_response: true,
          employee_comment: false,
        };
        setIsSpacesError(true);
      }
    });
  };

  // check for minimum character error.
  const validateMinCharacters = () => {
    viewResponse[0].questions_old_format.forEach((question, questionIndex) => {
      const { employee_response, employee_comment, answer_type } = question;

      if (
        employee_response &&
        employee_response.trim().length < 3 &&
        answer_type === "short_answer"
      ) {
        minCharacterError[questionIndex] = {
          employee_response: true,
          employee_comment: false,
        };
        setIsMinCharacterError(true);
      }
    });
  };

  const initializeEmpRatingState = (response: FeedbackResponseType) => {
    const hasEmployeeRatingArray: any[] = [];

    response?.questions_old_format?.forEach((question) => {
      const hasEmployeeRating =
        question?.hasOwnProperty("employee_rating") &&
        question.employee_rating !== null &&
        question.employee_rating !== "";

      if (question.answer_type === "starrating") {
        hasEmployeeRatingArray.push(hasEmployeeRating);
      } else {
        hasEmployeeRatingArray.push(null);
      }
    });

    setEmpRating(hasEmployeeRatingArray);
  };

  const hasSpecialCharacters = (input: string): boolean => {
    const specialCharacterPattern = /[#^*_+\=\;:"\\|<>/]/;
    return specialCharacterPattern.test(input);
  };

  const saveCall = async (e: any) => {
    const data = {
      form_team_id: formTeamId,
      response: responseToArray(viewResponse[0]),
      employee_feedback: viewResponse[0]?.employee_feedback,
      final_response: {},
    };

    const isAllFieldsFilled = data.response.some((item) => {
      return (
        (item.employee_response !== null &&
          item.employee_response !== undefined &&
          item.employee_response !== "") ||
        (item.employee_comment !== null &&
          item.employee_comment !== undefined &&
          item.employee_comment !== "") ||
        (item.employee_rating !== null &&
          item.employee_rating !== undefined &&
          item.employee_rating !== "")
      );
    });

    if (!isAllFieldsFilled) {
      handleShowToast("Please fill atleast one field to save !", "error", true);
      return;
    }

    try {
      const response = await dispatch2(
        postData(
          `${fillFormany}${
            feedbackRole.is_manager_feedback
              ? `?employee_id=${feedbackFormEmployeeId}`
              : ""
          }`,
          data
        )
      )
        .then((response) => {
          handleShowToast("Form Saved Succcessfully !", "success", true);
          //   setIsSaved(!isSaved);
          // window.location.reload();
          navigate("/dashboard");
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {
      console.log("Error:", error);
      handleShowToast(`${error?.response?.data?.message}`, "error", true);
    }
  };

  const updateCall = async (e: any) => {
    const data = responseToArray(viewResponse[0]);
    const data2 = {
      employee_feedback: viewResponse[0].employee_feedback,
    };
    // API call to update  the form response.
    try {
      const response = await dispatch2(
        patchData(
          `${editEmployeeSelfResponse}${responseIds?.response_id}${
            feedbackRole.is_manager_feedback
              ? `&employee_id=${feedbackFormEmployeeId}`
              : ""
          }`,
          data
        )
      );
      const response2 = await dispatch2(
        patchData(`${updateEmployeeFeedback}${responseIds?.response_id}`, data2)
      );
      // .then((response) => {
      handleShowToast("Updated Successfully!", "success", false);
      navigate("/dashboard");
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (e: any) => {
    if (responseIds?.response_id) {
      await updateCall(e);
    } else {
      await saveCall(e);
    }
  };

  const responseToArray = (response: FeedbackResponseType): Response1[] => {
    const responseArray: Response1[] = [];

    response.questions_old_format.forEach((question) => {
      if (question) {
        const {
          question_id,
          employee_response,
          employee_comment,
          employee_rating,
        } = question;

        const response1: Response1 = {
          question_id,
          employee_response: employee_response,
          employee_comment:
            employee_comment !== undefined ? employee_comment : "",
          employee_rating: employee_rating,
        };
        responseArray.push(response1);
      }
    });

    return responseArray;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    validateMinCharacters();
    validateSpacesError();

    // const isEveryEmpRatingTrue = empRating.every((questionEmpRating) => {
    //   return questionEmpRating === true;
    // });

    const isAnyFalseInEmpRating = empRating.some((questionEmpRating) => {
      return questionEmpRating === false;
    });

    let isAnySpecialCharError = false;

    Object.values(specialCharErrors).forEach((error) => {
      if (error) {
        isAnySpecialCharError = true;
      }
    });

    let isMinCharacterErrors = false;

    Object.values(minCharacterError).forEach((error: any) => {
      if (error.employee_response) {
        isMinCharacterErrors = true;
      }
    });

    let isSpacesError = false;

    Object.values(spacesErrors).forEach((error: any) => {
      if (error.employee_response) {
        isSpacesError = true;
      }
    });

    // Now you can use isEveryEmpRatingTrue to conditionally render content or take any other action based on the empRating state.
    if (isAnyFalseInEmpRating || isSpacesError || isMinCharacterErrors) {
      setFlag(true);
      setFlag2(true);

      handleShowToast("Please fill all details", "error", true);
      return;
    }
    await handleSave(e);

    // API call to submit the form.
    try {
      const response = await dispatch2(
        patchData(`${finalSubmit}${viewResponse[0]?.response_id}`, {})
      )
        .then((response) => {
          dispatch(setInPageLoading(true));
          handleShowToast("Form Submitted Successfully !", "success", true);
          //   dispatch(postDataSuccess(null));
          dispatch(setInPageLoading(false));
          navigate("/dashboard");
        })
        .catch((error) => {
          console.log(error);
          dispatch(setInPageLoading(false));
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (
    questionIndex: number,
    question_id: string,
    field: keyof Question,
    value: string,
    answer_type: string
  ): void => {
    const containsOnlySpaces = /^\s*$/.test(value);

    setViewResponse((prevResponse) => {
      // Create a new copy of the response array to update it
      const updatedResponse: FeedbackResponseType[] = prevResponse.map(
        (item, itemIndex) => {
          if (itemIndex === 0) {
            // Update only the first element (index 0) of the array
            const updatedQuestions: Question[] = item.questions_old_format.map(
              (question, qIndex) => {
                if (qIndex === questionIndex) {
                  // Update the specific question
                  return {
                    ...question,
                    [field]: value ? value : "",
                    question_id,
                  };
                }
                return question;
              }
            );

            return {
              ...item,
              questions_old_format: updatedQuestions,
            };
          }

          // For other elements (if any), keep them unchanged
          return item;
        }
      );

      return updatedResponse;
    });

    const updatedSpecialCharErrors = { ...specialCharErrors };
    const questionErrors = updatedSpecialCharErrors[questionIndex] || {
      employee_response: false,
      employee_comment: false,
    };

    const updatedSpacesErrors = { ...spacesErrors };
    const questionSpacesErrors = updatedSpacesErrors[questionIndex] || {
      employee_response: false,
    };

    const updatedMinCharacterError = { ...minCharacterError };
    const questionMinCharacterError = updatedMinCharacterError[
      questionIndex
    ] || {
      // employee_comment: false,
      employee_response: false,
    };

    // Check for special characters in employee_response and employee_comment
    if (field === "employee_response") {
      if (answer_type === "short_answer") {
        questionErrors.employee_response = hasSpecialCharacters(value);
      }
      questionSpacesErrors.employee_response = containsOnlySpaces;
      if (answer_type === "short_answer") {
        questionMinCharacterError.employee_response = value.length < 3;
      }
    } else if (field === "employee_comment") {
      questionErrors.employee_comment = hasSpecialCharacters(value);
      // questionMinCharacterError.employee_comment = value.length < 3;
    }

    // Update the specialCharErrors state
    updatedSpecialCharErrors[questionIndex] = questionErrors;
    setSpecialCharErrors(updatedSpecialCharErrors);

    updatedSpacesErrors[questionIndex] = questionSpacesErrors;

    setSpacesErrors(updatedSpacesErrors);

    updatedMinCharacterError[questionIndex] = questionMinCharacterError;

    setMinCharacterError(updatedMinCharacterError);

    // Check for any Special Character Error.
    let isAnySpecialCharError = false;

    Object.values(updatedSpecialCharErrors).forEach((error) => {
      if (error) {
        isAnySpecialCharError = true;
      } else {
        isAnySpecialCharError = false;
      }
    });

    // Check for any Spaces Error.
    let isAnySpacesError = false;
    Object.values(updatedSpacesErrors).forEach((categories) => {
      Object.values(categories).forEach((questions) => {
        Object.values(questions).forEach((error) => {
          if (error.employee_response) {
            isAnySpacesError = true;
          }
        });
      });
    });

    // Check for any Minimum Character Errors.
    let isMinCharacterErrors = false;
    Object.values(updatedMinCharacterError).forEach((categories) => {
      Object.values(categories).forEach((questions) => {
        Object.values(questions).forEach((error) => {
          if (error.employee_response) {
            isMinCharacterErrors = true;
          }
        });
      });
    });
    // setIsMinCharacterError(isMinCharacterErrors);
    // setIsSpacesError(isAnySpacesError);
    // setIsSpecialCharError(isAnySpecialCharError);
  };

  // Check for the response id is generated or not if response id is generated then go to Save and submit form else go to save form.
  useEffect(() => {
    const fetchResponseId = async () => {
      try {
        const response = await dispatch2(
          fetchData(
            `${getResponseId}${formTeamId}${
              feedbackRole.is_manager_feedback
                ? `&user_id=${feedbackFormEmployeeId}`
                : ""
            }`
          )
        );

        var response_id = response as any;
        setResponseIds(response as ResponseId);
        dispatch(setResponseId(response_id.response_id));
      } catch (error) {
        console.log(error);
      }
    };

    fetchResponseId();
  }, []);

  // API call to view specific response of the employee.
  useEffect(() => {
    const fetchEmployeeSelfResponse = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          //   fetchData(`${viewEmployeeSelfResponse}${formTeamId}`)
          fetchData(
            `${retrieveEmployeeFeedbackResponse}${formTeamId}${
              feedbackRole.is_manager_feedback
                ? `&user_id=${feedbackFormEmployeeId}`
                : ""
            }`
          )
        );
        setViewResponse(response as FeedbackResponseType[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };

    const fetchFormData = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${listFeedbackForm}&form_id=${formId}`)
          //   fetchData(
          //     `http://localhost:8000/employees/feedback/responses/?form_team_id=${formTeamId}`
          //   )
        );
        setViewResponse(response as FeedbackResponseType[]);
        dispatch(setIsLoading(false));
      } catch (error: any) {
        console.log("Error:", error);
      }
    };

    if (responseIds?.response_id) {
      fetchEmployeeSelfResponse();
    } else {
      fetchFormData();
    }
  }, [responseIds]);

  useEffect(() => {
    initializeEmpRatingState(viewResponse[0]);
  }, [viewResponse]);

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10, display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "75%" }}>
          {viewResponse.map((res, index) => {
            return (
              <Box
                key={index}
                component="form"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <FormControl>
                  <Box
                    sx={{
                      mt: 1,
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                      width: "100% ",
                    }}
                  >
                    <Typography
                      color={"#1B87C3"}
                      fontWeight={"bold"}
                      letterSpacing={3}
                      variant="h4"
                      sx={{ textDecoration: "underline" }}
                      id="form_name"
                    >
                      {res.form_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      m: 1,
                      mt: 2,
                      mb: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={"#1B87C3"}
                      id="form_description"
                    >
                      {res.form_description
                        ? `(${res.form_description})`
                        : "(No description)"}
                    </Typography>
                  </Box>

                  <Divider />

                  <Box sx={{ m: 2, p: 2, width: "75vw" }}>
                    {res.questions_old_format.map((question, questionIndex) => {
                      return (
                        <Box key={question.question_id}>
                          <Box>
                            <Box>
                              <Typography
                                fontWeight={"bold"}
                                fontSize={19}
                                variant="h6"
                                id={`question_${questionIndex + 1}`}
                              >
                                {questionIndex + 1 + "."} {question.question}
                              </Typography>
                            </Box>
                            <Box sx={{ ml: 2, mr: 2 }}>
                              {question.answer_type === "short_answer" ? (
                                <Box>
                                  <TextField
                                    required
                                    id={`employee_response_question_${
                                      questionIndex + 1
                                    }`}
                                    name={`employee_response`}
                                    size="small"
                                    maxRows={4}
                                    fullWidth
                                    color="success"
                                    multiline
                                    value={question.employee_response}
                                    // rows={1}
                                    sx={{
                                      m: 2,
                                      overflow: "auto",
                                    }}
                                    style={{ height: "auto" }}
                                    InputProps={{
                                      style: {
                                        minHeight: "unset",
                                      },
                                    }}
                                    onChange={(e) => {
                                      handleChange(
                                        questionIndex,
                                        question.question_id,
                                        "employee_response",
                                        e.target.value,
                                        question.answer_type
                                      );
                                    }}
                                    helperText={
                                      <>
                                        {specialCharErrors?.[questionIndex]
                                          ?.employee_response && (
                                          <Typography color={"error"}>
                                            Special Characters not allowed.
                                          </Typography>
                                        )}
                                        {spacesErrors?.[questionIndex]
                                          ?.employee_response && (
                                          <Typography color={"error"}>
                                            Only Spaces not allowed.
                                          </Typography>
                                        )}
                                        {minCharacterError?.[questionIndex]
                                          ?.employee_response && (
                                          <Typography color={"error"}>
                                            Minimum 3 characters required.
                                          </Typography>
                                        )}
                                      </>
                                    }
                                  />
                                </Box>
                              ) : question.answer_type === "multichoice" ? (
                                <Box>
                                  <Box
                                    sx={{
                                      display: "block",
                                      alignItems: "center",
                                      m: 1,
                                    }}
                                  >
                                    <RadioGroup
                                      id={`employee_response_question_${
                                        questionIndex + 1
                                      }`}
                                      name={`employee_response_radio`}
                                      value={
                                        question.employee_response
                                          ? question.employee_response
                                          : null
                                      }
                                      onChange={(e) => {
                                        handleChange(
                                          questionIndex,
                                          question.question_id,
                                          "employee_response",
                                          e.target.value,
                                          question.answer_type
                                        );
                                      }}
                                    >
                                      {question.answer_choices &&
                                        question.answer_choices.map(
                                          (
                                            ans: any,
                                            answerValueIndex: number
                                          ) => (
                                            <FormControlLabel
                                              id={`employee_response_question_${
                                                questionIndex + 1
                                              }_answer_value_${
                                                answerValueIndex + 1
                                              }`}
                                              required
                                              key={ans.option_id}
                                              value={ans.answer_value}
                                              control={
                                                <Radio
                                                  id={`employee_response_question_${
                                                    questionIndex + 1
                                                  }_answer_value_${
                                                    answerValueIndex + 1
                                                  }_radio_button`}
                                                  color="success"
                                                />
                                              }
                                              label={ans.answer_value}
                                            />
                                          )
                                        )}
                                    </RadioGroup>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      m: 1,
                                      p: 1,
                                    }}
                                  >
                                    <Typography
                                      fontWeight={"bold"}
                                      paragraph
                                      sx={{ width: "11rem" }}
                                    >
                                      Comments :{" "}
                                    </Typography>
                                    <TextField
                                      id={`employee_comment_question_${
                                        questionIndex + 1
                                      }`}
                                      name="employee_comment"
                                      color="success"
                                      fullWidth
                                      multiline
                                      maxRows={4}
                                      // rows={2}
                                      style={{
                                        height: "auto",
                                        overflow: "auto",
                                      }}
                                      value={question?.employee_comment}
                                      onChange={(e) => {
                                        handleChange(
                                          questionIndex,
                                          question.question_id,
                                          "employee_comment",
                                          e.target.value,
                                          question.answer_type
                                        );
                                      }}
                                      helperText={
                                        <>
                                          {specialCharErrors?.[questionIndex]
                                            ?.employee_comment && (
                                            <Typography color={"error"}>
                                              Special Characters not allowed.
                                            </Typography>
                                          )}
                                          {minCharacterError?.[questionIndex]
                                            ?.employee_comment && (
                                            <Typography color={"error"}>
                                              Minimum 3 characters required.
                                            </Typography>
                                          )}
                                        </>
                                      }
                                    />
                                  </Box>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    m: 1,
                                    p: 1,
                                  }}
                                >
                                  {/* <Typography
                                    fontWeight={"bold"}
                                    paragraph
                                    sx={{
                                      width: "8.5rem",
                                      mt: 0.5,
                                    }}
                                  >
                                    Rating :{" "}
                                  </Typography> */}
                                  <Rating
                                    id={`employee_rating_question_${
                                      questionIndex + 1
                                    }`}
                                    size="large"
                                    precision={0.5}
                                    onChange={(e, newValue) => {
                                      handleChange(
                                        questionIndex,
                                        question.question_id,
                                        "employee_rating",
                                        newValue?.toString() || "",
                                        question.answer_type
                                      );
                                    }}
                                    onChangeActive={(event, newHover) => {
                                      // Update the hoverEffect state when the user hovers over the question
                                      setHoverEffect((prevHoverEffect) => {
                                        const updatedHoverEffect = {
                                          ...prevHoverEffect,
                                        };
                                        // Set the employee_rating value for the corresponding question
                                        updatedHoverEffect[questionIndex] = {
                                          employee_rating: newHover,
                                        };
                                        return updatedHoverEffect;
                                      });
                                    }}
                                    value={parseFloat(
                                      question?.employee_rating
                                    )}
                                    sx={{
                                      color: "#1B87C3",
                                    }}
                                    aria-required="true"
                                  />
                                  {!empRating[questionIndex] && flag && (
                                    <Typography sx={{ m: 0.5 }} color={"error"}>
                                      This field is required.
                                    </Typography>
                                  )}
                                  {value !== null && (
                                    <Typography
                                      sx={{
                                        textAlign: "center",
                                        ml: 2,
                                      }}
                                    >
                                      {
                                        labels[
                                          hoverEffect?.[questionIndex]
                                            ?.employee_rating !== null
                                            ? hoverEffect?.[questionIndex]
                                                ?.employee_rating
                                            : value
                                        ]
                                      }
                                    </Typography>
                                  )}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      m: 1,
                      p: 1,
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      paragraph
                      sx={{ width: "11rem" }}
                    >
                      Feedback :{" "}
                    </Typography>
                    <TextField
                      id={`employee_feedback`}
                      name="employee_comment"
                      color="success"
                      fullWidth
                      multiline
                      maxRows={4}
                      // rows={2}
                      style={{
                        height: "auto",
                        overflow: "auto",
                      }}
                      value={res.employee_feedback}
                      onChange={(e) => {
                        setViewResponse((prevViewResponse) => {
                          if (prevViewResponse.length > 0) {
                            // Make sure the array is not empty
                            return [
                              {
                                ...prevViewResponse[0], // Copy the first element
                                employee_feedback: e.target.value,
                              },
                              ...prevViewResponse.slice(1), // Copy the rest of the array
                            ];
                          } else {
                            // Handle the case where the array is empty
                            return prevViewResponse;
                          }
                        });
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      p: 1,
                      m: 1,
                    }}
                  >
                    <Button
                      id="save_button"
                      color="success"
                      size="large"
                      variant="outlined"
                      //   disabled={!allRatingsFilled}
                      onClick={(e) => {
                        handleSave(e);
                      }}
                      disabled={isSpecialCharError}
                      sx={{ m: 1 }}
                    >
                      {" "}
                      Save
                    </Button>
                    {viewResponse[0]?.response_id && (
                      <Button
                        id="mark_for_submission_button"
                        type="submit"
                        color="success"
                        size="large"
                        variant="contained"
                        disabled={
                          res.final_submit_done ||
                          responseId?.response_id === null
                        }
                        sx={{ m: 1 }}
                      >
                        Mark for Submission
                      </Button>
                    )}
                  </Box>
                  {/* )} */}
                </FormControl>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default FeedBackFillFOrm;
